// import img01 from "../../images/categories/louvre/louvre_lights_on.jpg";
// import img02 from "../../images/categories/louvre/louvre_lights_on.jpg";
import img01 from "../../images/categories/louvre/louvre_lights_on.jpg";
import img02 from "../../images/categories/louvre/louvre_lights_off.jpg";
import img03 from "../../images/categories/louvre/louvre_rooftops_detail.jpg";
import img04 from "../../images/categories/louvre/pyramide_carouseel.jpg";
import img05 from "../../images/categories/louvre/louvre_right_side.jpg";
import img06 from "../../images/categories/louvre/louvre_front_side.jpg";
import img07 from "../../images/categories/louvre/landscape_right_side.jpg";

const texts = {
  text_1: {
    fr: `La première fois que j'ai escaladé le musée, il était trop tard et
  les lumières de la pyramide étaient éteintes, j'ai décidé de revenir
  quelques jours plus tard. Je cours alors jusqu'au même spot que la
  dernière fois, seulement pour une photo, prends mon appareil, shoot
  avec un objo complètement différent de la dernière fois, mais grâce
  à Photoshop et un bon recadrage, les deux images se ressemblent
  comme deux gouttes d'eau dont la seule différence sera le temps...
  Même si je me précipitais, je n'ai pas eu le temps de prendre le
  dernier métro...`,
    en: `The first time I climbed the museum, it was too late and the pyramid
  lights were off, I decided to come back a few days later. Rush to the
  same spot than last time, only for one picture, take my camera, shoot
  with a completly different objo than last time, but thanks to
  Photoshop and good cropping, look like the same picture at different
  time ... Even if I rushed, I failed to catch the last metro ...`,
  },
};

export const categoryData = {
  src: img01,
  link: "louvre",
  title: "Louvre",
  description:
    "the most visited museum in the world from exclusive points of view",
  background: "#1B1613",
  primary: "#F8AD5B",
  secondary: "#fff",
  disabled: true,

  categoryImg: { img: img01, location: "" },
  themes: {
    yRange: [0, 500, 550],
    background: ["#000", "#000", "#000"],
    textColor: ["#fff", "#000", "#fff"],
  },
  contents: [
    {
      text: (
        <div className="text description text-align-center">
          <p>{texts.text_1.en}</p>
        </div>
      ),

      size: 12,
    },
    {
      img: img02,

      size: 12,
    },
    {
      img: img03,
      size: 4,
    },
    {
      img: img04,
      size: 4,
    },
    {
      img: img05,
      size: 4,
    },
    {
      img: img06,
      size: 4,
    },
    {
      img: img07,
      size: 8,
    },
  ],
};
