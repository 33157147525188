import img01 from "../../images/categories/opera-garnier/sico_shoot_landscape.jpg";
import img02 from "../../images/categories/opera-garnier/sunset_wide_angle.jpg";
import img03 from "../../images/categories/opera-garnier/sico_shoot_landscape_2.jpg";
import img04 from "../../images/categories/opera-garnier/sico_taking_pictures.jpg";

export const categoryData = {
  src: img01,
  link: "opera-garnier",
  title: "Opera Garnier",
  description: "Best place to work in remote",
  background: "#F1ECEA",
  primary: "#407650",
  secondary: "#778C9C",
  disabled: true,
  comingSoon: true,

  themes: {
    yRange: [0, 140, 300],
    background: ["#47725A", "#B9C4E6", "#7690A9"],
    textColor: ["#fff", "#fff", "#fff"],
  },
  categoryImg: { img: img01, location: "" },
  contents: [
    {
      text: (
        <div className="text description noMargin text-align-center">
          <p>{`Si un jour on me demande quel lieu de télétravail j'ai préféré, ma question sera vite répondu
          Je ne m'attendais pas un jour dire ça, mais en effet, j'ai télétravaillé sur ce splendide rooftop si emblématique`}</p>
        </div>
      ),

      size: 12,
    },
    {
      img: img02,
      size: 4,
    },
    {
      img: img03,
      size: 8,
    },
    {
      img: img04,
      size: 4,
      position: 3,
    },
  ],
};
