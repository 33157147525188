import { useEffect, useRef, useState } from "react";

//context
import { useGlobalStateContext } from "../context/globalContext";

const CustomCursor = () => {
  const { cursorType } = useGlobalStateContext();
  const mainCursor = useRef(null);
  const secondaryCursor = useRef(null);

  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  const [mainPosition, setMainPosition] = useState({ x: 0, y: 0 });
  const [secondaryPosition, setSecondaryPosition] = useState({
    x: 400,
    y: 400,
  });

  const onMouseMove = (event) => {
    const { clientX, clientY } = event;
    const mouseX = clientX;
    const mouseY = clientY;
    positionRef.current.mouseX =
      mouseX - secondaryCursor.current.clientWidth / 2;
    positionRef.current.mouseY =
      mouseY - secondaryCursor.current.clientHeight / 2;

    setMainPosition({
      x: mouseX - mainCursor.current.clientWidth / 2,
      y: mouseY - mainCursor.current.clientHeight / 2,
    });
    // mainCursor.current.style.transform = `translate3d(${
    //   mouseX - mainCursor.current.clientWidth / 2
    // }px,${mouseY - mainCursor.current.clientHeight / 2}px,0)`;
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;

      if (!destinationX | !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }
      setSecondaryPosition({ x: destinationX, y: destinationY });
      // secondaryCursor.current.style.transform = `translate3d(${destinationX}px,${destinationY}px,0)`;
    };

    followMouse();
  }, []);

  return (
    <div id="cursor" className={`${!!cursorType ? cursorType : ""}`}>
      <div
        className="main-cursor"
        style={{
          left: mainPosition.x,
          top: mainPosition.y,
        }}
        ref={mainCursor}
      ></div>
      <div
        className="secondary-cursor"
        style={{
          left: secondaryPosition.x,
          top: secondaryPosition.y,
        }}
        ref={secondaryCursor}
      ></div>
    </div>
  );
};

export default CustomCursor;
