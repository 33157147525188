import img01 from "../../images/categories/marmande-church/church_maxime_sunset.jpeg";
import img02 from "../../images/categories/marmande-church/golden_hours_maxime.jpg";
import img03 from "../../images/categories/marmande-church/maxime_contrast_cross.jpg";
import img04 from "../../images/categories/marmande-church/place_du_marche.jpg";
import img05 from "../../images/categories/marmande-church/maxime_cross.jpg";
import img06 from "../../images/categories/marmande-church/willian_sunset.jpg";
import img07 from "../../images/categories/marmande-church/maxime_climb.jpg";

export const categoryData = {
  src: img01,
  link: "marmande-church",
  title: "Marmande Church",
  description:
    "nothing fascinating but it's still the church of the city where I grew up",
  background: "#A08D9E",
  primary: "#86BDE2",
  secondary: "#fff",
  disabled: true,

  themes: {
    yRange: [0, 150, 300],
    background: ["#A08D9E", "#C3A087", "#15100C"],
    textColor: ["#fff", "#fff", "#fff"],
  },
  categoryImg: { img: img01, location: "maxime" },
  contents: [
    {
      img: img02,
      location: "Église de Marmande",
      size: 4,
    },
    {
      img: img03,
      size: 4,
    },
    {
      text: (
        <div className="text description">
          <p>
            Ce n'est sans doute pas un monument spectaculaire à vos yeux, mais
            pour moi ça l'est tout de même un peu, c'est ici que j'aurai passé
            18 années de ma vie. Alors oui, le jour où nous avons grimpé
            l'église de la ville dans laquelle j'ai grandi, ce fut un
            accomplissement symbolique à mes yeux.
          </p>
        </div>
      ),

      size: 4,
    },
    {
      img: img04,
      location: "Place du marché - Marmande",
      size: 4,
      position: 2,
    },
    {
      img: img05,
      size: 4,
      position: 7,
    },
    {
      img: img06,
      size: 4,
      position: 3,
    },
    {
      img: img07,
      size: 4,
      position: 8,
    },
  ],
};
