import { Html, PerspectiveCamera, Stars } from "@react-three/drei";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as THREE from "three";
import { a, config, useSpring } from "@react-spring/three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { motion } from "framer-motion";
import { useGlobalDispatchContext } from "../context/globalContext";
import { Link } from "react-router-dom";
import VirtualScroll from "virtual-scroll";

const About = () => {
  const [hidden, setHidden] = useState(false);
  const dispatch = useGlobalDispatchContext();

  const onCursor = (cursorType) => {
    dispatch({ type: "CURSOR_TYPE", cursorType: cursorType });
  };

  useEffect(() => {
    onCursor();
  }, []);

  return (
    <div id="about">
      <div
        className="back"
        onMouseEnter={() => onCursor("hovered")}
        onMouseLeave={onCursor}
      >
        <Link to="/gallery">BACK</Link>
      </div>
      <div className="canvas-scene">
        <motion.span
          initial={{ opacity: 0 }}
          animate={!hidden ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.6 }}
          className="scroll"
        >
          SCROLL
        </motion.span>
        <Canvas
          dpr={window.devicePixelRatio}
          gl={{
            antialias: true,
            pixelRatio: window.devicePixelRatio,
          }}
        >
          <Suspense
            fallback={
              <Html fullscreen>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1>Loading...</h1>
                </div>
              </Html>
            }
          >
            <CanvasScene
              dispatch={dispatch}
              hidden={hidden}
              setHidden={(res) => setHidden(res)}
            />
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
};

export default About;

const CanvasScene = ({ setHidden, dispatch }) => {
  const requestRef = useRef();
  const state = useRef({ speed: 0, position: 0 });
  const scroll = useRef();
  const groupRef = useRef();
  const [count, setCount] = useState(0);

  const onScroll = (e) => {
    state.current.speed -= e.deltaY * 0.0003;

    if (state.current.position > 2) {
      setHidden(true);
    } else if (state.current.position <= 2) {
      setHidden(false);
    }
  };

  const raf = () => {
    state.current.position += state.current.speed;

    state.current.speed *= 0.9;

    // state.current.position -= state.current.position * 0.03;

    requestRef.current = requestAnimationFrame(raf);
  };

  useEffect(() => {
    scroll.current = new VirtualScroll({
      useTouch: true,
      mouseMultiplier: 0.4,
      useKeyboard: true,
    });

    scroll.current.on(onScroll);

    requestRef.current = requestAnimationFrame(raf);
    return () => {
      scroll.current.destroy();
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  useFrame(({ camera }) => {
    const radius = 10;
    const tempVector = new THREE.Vector3(0, camera.position.y - 0.01, 0);

    camera.position.set(
      radius * Math.sin(state.current.position * Math.PI * 0.1),
      camera.position.y - state.current.speed * 2,
      radius * Math.cos(state.current.position * Math.PI * 0.1)
    );

    camera.lookAt(tempVector);
  });

  useEffect(() => {
    if (count > 5) {
      const promptRes = window.prompt("will you give me a kiss ? 😚");
      if (
        promptRes?.toLowerCase() === "yes" ||
        promptRes?.toLowerCase() === "oui"
      ) {
        localStorage.setItem("unlock-disabled-categories", true);
        window.alert(
          "it's time to go back to the gallery right ?🤫\nDon't forget to refresh the page"
        );
      } else if (
        promptRes?.toLowerCase() === "no" ||
        promptRes?.toLowerCase() === "non"
      ) {
        window.alert("😒");
        localStorage.removeItem("unlock-disabled-categories");
      }
    }
  }, [count]);

  return (
    <>
      <group name="Camera" position={[0, 0, 0]}>
        <PerspectiveCamera makeDefault far={1000} near={0.1} fov={60} />
      </group>
      <Stars />
      <ambientLight />
      <pointLight intensity={1} color="white" position={[5, 1, 5]} />

      <LogoInsta dispatch={dispatch} />
      <LogoMail dispatch={dispatch} />
      <group visible ref={groupRef}>
        <AboutText />

        <group position={[4, -6, 2]} rotation={[0, Math.PI / 4, 0]}>
          <Text children="Hi, I am" size={0.5} height={0.1} />
          <Text
            children="Colin Schmitt"
            position={[0, -1, 0]}
            rotation={[0, Math.PI / 12, 0]}
            size={0.5}
            height={0.1}
          />
          <Text
            children="I'm 23"
            position={[0, -2, 0]}
            rotation={[0, Math.PI / 8, 0]}
            size={0.5}
            height={0.1}
          />
        </group>

        <group position={[0, -14, 0]} rotation={[0, (3 * Math.PI) / 4, 0]}>
          <Text
            children="Few years ago I discovered a passion for the photography"
            size={0.3}
            bevelSize={0.02}
            height={0.1}
          />
          <Text
            position-y={-0.5}
            bevelSize={0.02}
            children="it's a way for me to remember every detail of a moment"
            size={0.3}
            height={0.1}
          />
          <Text
            position-y={-1}
            bevelSize={0.02}
            children="But in first time, I just saw photography as something artistic"
            size={0.3}
            height={0.1}
          />
          <Text
            position-y={-1.5}
            bevelSize={0.02}
            children="Bcs when I was in middle school, I discovered Photoshop and all these stuff"
            size={0.3}
            height={0.1}
          />
          <Text
            children="Then, I wanted to mix reality and unreality"
            size={0.3}
            height={0.1}
            position-y={-2}
            bevelSize={0.02}
          />
        </group>
        <group position={[0, -22, 0]} rotation={[0, (3.5 * -Math.PI) / 4, 0]}>
          <Text
            size={0.3}
            height={0.1}
            bevelSize={0.02}
            position-y={-1}
            position-x={-2.1}
            children="Most of these pics"
          />
          <Text
            size={0.3}
            height={0.1}
            bevelSize={0.02}
            position-y={-1}
            position-x={0.5}
            children="hide"
            onClick={() => {
              setCount(count + 1);
            }}
          />
          <Text
            size={0.3}
            height={0.1}
            bevelSize={0.02}
            position-y={-1}
            position-x={2.8}
            children="a lot of stories..."
          />
        </group>
        <group position={[-3, -31, 2]} rotation={[0, (2 * -Math.PI) / 6, 0]}>
          <Text
            size={0.6}
            height={0.3}
            position-y={-1.6}
            bevelSize={0.04}
            children="Have a good day"
          />
        </group>
      </group>
    </>
  );
};

const Text = ({
  size = 1,
  height = 0.2,
  bevelSize = 0.04,
  children,
  vAlign = "center",
  hAlign = "center",
  ...props
}) => {
  if (window.innerWidth <= 1024) {
    size /= 2;
    height /= 2;
  }
  const font = useLoader(
    THREE.FontLoader,
    "fonts/Montserrat_SemiBold_Regular.json"
  );
  const textConfig = useMemo(
    () => ({
      font,
      size: size,
      height: height,
      curveSegments: 12,
      bevelEnabled: true,
      bevelThickness: 0.05,
      bevelSize: bevelSize,
      //   bevelOffset: 0.01,
      bevelSegments: 10,
    }),
    []
  );
  const material = useMemo(() => new THREE.MeshNormalMaterial(), []);

  useLayoutEffect(() => {
    const size = new THREE.Vector3();
    mesh.current.geometry.computeBoundingBox();
    mesh.current.geometry.boundingBox.getSize(size);

    mesh.current.position.x =
      hAlign === "center" ? -size.x / 2 : hAlign === "right" ? 0 : -size.x;
    mesh.current.position.y =
      vAlign === "center" ? -size.y / 2 : vAlign === "top" ? 0 : -size.y;
  }, []);

  const mesh = useRef();

  return (
    <group {...props}>
      <mesh material={material} ref={mesh}>
        <textGeometry args={[children, textConfig]} />
      </mesh>
    </group>
  );
};

const AboutText = () => {
  const [hovered, setHovered] = useState(false);
  const spring = useSpring({
    config: config.wobbly,
    rotation: hovered ? Math.PI : 0,
  });

  // mobile
  const boxProps = useRef({
    size: window.innerWidth <= 1024 ? [3, 0.5, 0.1] : [6, 0.5, 0.1],
    position: window.innerWidth <= 1024 ? [0, -0.5, 0.1] : [0, -1, 0.1],
    maskSize: window.innerWidth <= 1024 ? [2.5, 0.5, 0.25] : [5, 1, 0.5],
  });

  return (
    <group>
      <a.group
        rotation-x={spring.rotation}
        onPointerEnter={() => setHovered(true)}
        onPointerLeave={() => setHovered(false)}
      >
        <Text children="ABOUT" />
        <mesh>
          <boxGeometry args={boxProps.current.maskSize} />
          <meshBasicMaterial opacity={0} transparent />
        </mesh>
      </a.group>

      <mesh
        position={boxProps.current.position}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <boxGeometry args={boxProps.current.size} />
        <meshNormalMaterial />
      </mesh>
    </group>
  );
};

const LogoInsta = ({ dispatch }) => {
  const material = useMemo(() => new THREE.MeshNormalMaterial(), []);
  const gltf = useLoader(GLTFLoader, "gltf/instagram_logo/scene.gltf");
  const [hovered, setHovered] = useState(false);
  const groupPosition = useRef(
    window.innerWidth <= 1024 ? [2, -0.1, 0.1] : [4, -0.1, 0.1]
  );
  const props = useSpring({
    scale: hovered
      ? window.innerWidth <= 1024
        ? [0.003, 0.003, 0.01]
        : [0.006, 0.006, 0.02]
      : window.innerWidth <= 1024
      ? [0.0025, 0.0025, 0.01]
      : [0.005, 0.005, 0.02],
    rotation: hovered ? [0, (-2 * Math.PI) / 2, 0] : [0, 0, 0],
    config: config.stiff,
  });

  useEffect(() => {
    const box = new THREE.Box3().setFromObject(gltf.scene);
    const center = box.getCenter(new THREE.Vector3());

    const positionXYZ = 0;
    // gltf.scene.position.x = positionXYZ - center.x;
    gltf.scene.position.x = 750;
    gltf.scene.position.y = 549.9999847412109;
    gltf.scene.position.z = 1.2212453270876722e-13;
    // gltf.scene.position.y = positionXYZ - center.y;
    // gltf.scene.position.z = positionXYZ - center.z;

    gltf.scene.children[0].traverse((n) => {
      if (n.isObject3D) {
        n.material = material;
      }
    });
  }, [gltf]);

  const onPointerEnter = () => {
    setHovered(true);
    dispatch({ type: "CURSOR_TYPE", cursorType: "hovered" });
  };

  const onPointerLeave = () => {
    setHovered(false);
    dispatch({ type: "CURSOR_TYPE", cursorType: false });
  };

  return (
    <a.group
      name="logo-insta"
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      position={groupPosition.current}
      rotation={props.rotation}
      scale={props.scale}
    >
      <mesh
        onClick={() => window.alert("not available for now")}
        scale={[200, 200, 50]}
      >
        <boxGeometry args={[1, 1, 1]} />

        <meshBasicMaterial opacity={0} transparent />
      </mesh>
      <primitive object={gltf.scene} />
    </a.group>
  );
};

const LogoMail = ({ dispatch }) => {
  const material = useMemo(() => new THREE.MeshNormalMaterial(), []);
  const gltf = useLoader(GLTFLoader, "gltf/email_logo/scene.gltf");
  const model = gltf.nodes.Gmail_1;
  const groupPosition = useRef(
    window.innerWidth <= 1024 ? [-2, 0, 0.1] : [-4, 0, 0.1]
  );
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const props = useSpring({
    scale: hovered
      ? window.innerWidth <= 1024
        ? [0.03, 0.03, 0.1]
        : [0.06, 0.06, 0.1]
      : window.innerWidth <= 1024
      ? [0.025, 0.025, 0.1]
      : [0.05, 0.05, 0.1],
    rotation: hovered ? [0, 0, 2 * Math.PI] : [0, 0, 0],
    config: config.stiff,
  });

  useEffect(() => {
    const box = new THREE.Box3().setFromObject(gltf.scene);
    const center = box.getCenter(new THREE.Vector3());
    const positionX = -48.12114715576172;
    model.position.x = positionX + positionX - center.x;

    model.traverse((n) => {
      if (n.isObject3D) {
        n.material = material;
      }
    });
  }, [gltf]);

  const onPointerEnter = () => {
    setHovered(true);
    dispatch({ type: "CURSOR_TYPE", cursorType: "hovered" });
  };

  const onPointerLeave = () => {
    setHovered(false);
    dispatch({ type: "CURSOR_TYPE", cursorType: false });
  };

  const onClick = () => {
    const elem = document.createElement("textarea");
    elem.value = "colinschmitt47@gmail.com";
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 3000);
  };

  return (
    <>
      <Copied clicked={clicked} />
      <a.group
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onClick={onClick}
        position={groupPosition.current}
        rotation={props.rotation}
        scale={props.scale}
      >
        <primitive object={model} />
      </a.group>
    </>
  );
};

const Copied = ({ clicked }) => {
  const font = useLoader(
    THREE.FontLoader,
    "fonts/Montserrat_SemiBold_Regular.json"
  );
  const textConfig = useMemo(
    () => ({
      font,
      size: 0.2,
      height: 0.05,
      curveSegments: 12,
      bevelEnabled: true,
      bevelThickness: 0.05,
      bevelSize: 0.02,
      //   bevelOffset: 0.01,
      bevelSegments: 10,
    }),
    []
  );
  const grouPosition = useRef(
    window.innerWidth <= 1024 ? [-2.35, 0.3, 0] : [-4.35, 0.8, 0]
  );
  const props = useSpring({
    opacity: clicked ? 1 : 0,
    config: config.slow,
  });
  return (
    <group position={grouPosition.current}>
      <mesh>
        <textGeometry args={["copied", textConfig]} />
        <a.meshNormalMaterial transparent opacity={props.opacity} />
      </mesh>
    </group>
  );
};
