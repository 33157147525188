import img01 from "../../images/categories/portrait/raphael_stairs.jpg";
import img02 from "../../images/categories/portrait/self_portrait_red_light.jpg";
import img03 from "../../images/categories/portrait/eva_ste_catherine.jpg";
import img04 from "../../images/categories/portrait/solenn_church.jpg";
import img05 from "../../images/categories/portrait/aaron_glass.jpg";
import img06 from "../../images/categories/portrait/chloe_rooftop.jpg";
import img07 from "../../images/categories/portrait/pauline_flowers.jpg";
import img08 from "../../images/categories/portrait/hugo_pere_lachaise.jpg";
import img09 from "../../images/categories/portrait/maxime_kenzo.jpg";
import img10 from "../../images/categories/portrait/self_in_grass.jpg";
import img11 from "../../images/categories/portrait/nora_garden.jpg";
// import img12 from "../../images/categories/portrait/ilyes_louvre.jpg";

export const categoryData = {
  src: img01,
  link: "portrait",
  title: "Portrait",
  description:
    "Honestly that's not my cup of tea, but it's okay, if is to shoot some friends sometimes",
  background: "#141515",
  primary: "#671E23",
  secondary: "#EBCDC2",
  themes: {
    yRange: [0, 90],
    background: ["#A07255", "#0B0B0B"],
    textColor: ["#000", "#fff"],
  },
  categoryImg: { img: img01, location: "Akela" },
  contents: [
    {
      img: img02,
      location: "My room - Paris",
      size: 4,
    },
    {
      img: img03,
      location: "Eva - rue Ste Catherine - Bordeaux",
      position: 5,
      size: 8,
    },
    {
      img: img04,
      location: "Solenn - Ste Clothilde - Paris",
      size: 3,
    },
    {
      img: img05,
      location: "Aaron - rue d'orsel - Paris",
      size: 3,
    },
    {
      img: img06,
      location: "Chloë - Sur les toits - Paris",
      size: 3,
    },
    {
      img: img07,
      location: "Pauline - La Filhole - Marmande",
      size: 3,
    },
    {
      img: img08,
      location: "Hugo - Cimetière du père Lachaise -  Paris",
      size: 3,
    },
    {
      img: img09,
      location: "Maxime - KENZO - Paris",
      size: 3,
    },
    {
      img: img10,
      location: "4d617576657a696e2d3437",
      size: 3,
    },
    {
      img: img11,
      location: "Nora - La Vilette - Paris",
      size: 3,
    },
  ],
};
