import img01 from "../../images/categories/montreal/autoportrait_windows_hand.jpg";
import img02 from "../../images/categories/montreal/1000_in_the_fog.jpg";
import img03 from "../../images/categories/montreal/man_walking_in_the_snow.jpg";
import img04 from "../../images/categories/montreal/4x4_vieux_montreal.jpg";
import img05 from "../../images/categories/montreal/landscape_city_from_belvedere.jpg";
import img06 from "../../images/categories/montreal/orange_moon.jpg";
import img07 from "../../images/categories/montreal/city_from_belvedere.jpg";
import img08 from "../../images/categories/montreal/architecture_contrast.jpg";
import img09 from "../../images/categories/montreal/mile_end_alley.jpg";
import img10 from "../../images/categories/montreal/skeleton_graffiti.jpg";
import img11 from "../../images/categories/montreal/phillips_square.jpg";
import img12 from "../../images/categories/montreal/world_trade_interior.jpg";
import img13 from "../../images/categories/montreal/pool_from_above.jpg";
import img14 from "../../images/categories/montreal/view_from_belvedere.jpg";

export const categoryData = {
  src: img01,
  link: "montreal",
  title: "Montréal",
  description: "I lived there, but the cold killed me... 🥶",
  background: "#3BAACC",
  primary: "#0B0D07",
  secondary: "#E8C692",
  comingSoon: false,

  categoryImg: { img: img01 },
  themes: {
    yRange: [0, 400],
    background: ["#2A677D", "#021B30"],
    textColor: ["#fff", "#fff"],
  },
  contents: [
    {
      text: (
        <div className="text description text-align-center">
          {/* Je suis arrivé vers la fin de l'hiver, c'est pas pour autant que j'ai
          raté l'hiver... */}
          {/* english version */}I arrived towards the end of winter, but that
          doesn't mean I missed winter...
        </div>
      ),

      size: 6,
      position: 4,
    },
    {
      img: img02,
      size: 4,
      position: 5,
    },
    {
      img: img03,
      size: 4,
      position: 5,
    },
    {
      img: img04,
      size: 4,
      position: 5,
    },
    {
      img: img05,
      size: 10,
      position: 2,
    },
    {
      img: img06,
      size: 5,
      position: 2,
    },
    {
      img: img07,
      size: 5,
      position: 7,
    },
    {
      text: (
        <div className="text description">
          {/* french version */}
          {/* english version */}
          This day I didn't knew I was going to see such a spectacle
        </div>
      ),
      size: 4,
      position: 2,
    },

    {
      img: img08,
      size: 4,
      position: 1,
    },
    {
      text: (
        <div className="text description">
          {/* french version */}
          {/* english version */}
          Something that i'm in love with, is this contrast of architecture.
          There is different neighborhoods some with tall buildings and some
          others more urban
          <br />
          Even in the downtown you can see differents types of buildings
          architecture, some are really modern, others are made of stones
        </div>
      ),

      size: 4,
    },
    {
      img: img09,
      size: 4,
    },

    {
      img: img10,
      size: 4,
    },
    {
      img: img11,
      size: 4,
    },
    {
      img: img12,
      size: 4,
    },

    {
      img: img13,
      size: 4,
    },
    {
      img: img14,
      size: 4,
    },
    {
      text: (
        <div className="text description">
          I'm now waiting for the winter, for christmas, curious to see the snow
          and the mood during theses periods
          <br />
          But first, there is autumn, where leafs are colorful
        </div>
      ),
      size: 4,
    },
  ],
};
