import { motion, useMotionValue, useTransform } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import { useEffect, useRef } from "react";
import { convertVhToPx } from "../utils/math";

const ScrollComponent = ({
  loaded,
  id,
  className,
  themes,
  children,
  scroll,
  ...props
}) => {
  const scrollRef = useRef();
  // const scroll = useRef();

  // motion values
  const scrollY = useMotionValue(0);
  let yRange = themes.yRange;
  yRange = yRange.map((elem) => convertVhToPx(elem));
  const bgColorOutput = themes.background;
  const txtColorOutput = themes.textColor;

  const background = useTransform(
    scrollY,
    yRange ? yRange : [0, 100],
    yRange ? bgColorOutput : [bgColorOutput, "#fff"]
  );
  const textColor = useTransform(
    scrollY,
    yRange ? yRange : [0, 100],
    yRange ? txtColorOutput : [txtColorOutput, "#000"]
  );

  useEffect(() => {
    if (loaded) {
      scroll.current = new LocomotiveScroll({
        el: scrollRef.current,
        lerp: 0.08,
        smooth: true,
        repeat: true,
        tablet: { smooth: true, direction: "vertical" },
        smartphone: { smooth: true, direction: "vertical" },
      });

      if (yRange)
        scroll.current.on("scroll", (args) => {
          scrollY.set(args.scroll.y);
        });
    }

    return () => {
      if (scroll.current) scroll.current.destroy();
    };
  }, [loaded]);

  return (
    <motion.div
      style={{ zIndex: 9 }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: loaded ? 1 : 0,
        transition: { duration: 0.4 },
      }}
      exit={{ opacity: 0, transition: { delay: 1.3 } }}
      id={id}
      {...props}
      className={className}
    >
      <motion.div style={{ color: textColor }} ref={scrollRef}>
        <motion.div
          className="background"
          style={{
            background: background,
          }}
        ></motion.div>
        {children}
      </motion.div>
    </motion.div>
  );
};

export default ScrollComponent;
