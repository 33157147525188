import img01 from "../../images/categories/moments/homeless_&_birds.jpg";
import img02 from "../../images/categories/moments/boy_bubble.jpg";
import img03 from "../../images/categories/moments/caroussel_bubbles.jpg";
import img04 from "../../images/categories/moments/manifestations_boris-johson_girl.jpg";
import img05 from "../../images/categories/moments/manifestations_boris-johson_police.jpg";
import img06 from "../../images/categories/moments/crowd_gilets_jaunes.jpg";
import img07 from "../../images/categories/moments/trash_in_fire.jpg";
import img08 from "../../images/categories/moments/firefighter_manifestations.jpg";
import img09 from "../../images/categories/moments/gilets_jaunes_selfie.jpg";

export const categoryData = {
  src: img01,
  link: "moments",
  title: "Moments",
  description: "Some pictures taken in the street",
  background: "#2B323A",
  primary: "#CDC2BE",
  secondary: "#fff",

  categoryImg: { img: img01, location: "centre pompidou - Paris" },
  themes: {
    yRange: [0, 90, 150],
    background: ["#2F3F4A", "#E6BC91", "#0D0702"],
    textColor: ["#fff", "#fff", "#fff"],
  },
  contents: [
    {
      img: img02,
      location: "Carrousel du Louvre - Paris",
      size: 6,
    },
    {
      img: img03,
      size: 6,
    },
    {
      img: img04,
      location: "Westminster - London",
      size: 4,
    },
    {
      img: img05,
      size: 4,
    },
    {
      img: img06,
      location: "Barbes - Paris",
      size: 4,
    },
    {
      img: img07,
      location: "Gare de l'Est - Paris",
      size: 6,
    },
    {
      img: img08,
      size: 3,
    },
    {
      img: img09,
      location: "Kléber - Paris",
      size: 3,
    },
  ],
};
