import img01 from "../../images/categories/grand-palais/grand_palais_landscape.jpg";
import img02 from "../../images/categories/grand-palais/colin_admiring_canopy.jpg";
import img03 from "../../images/categories/grand-palais/colin_standing_canopy.jpg";
import img04 from "../../images/categories/grand-palais/standing_on_horses.jpg";

import img05 from "../../images/categories/grand-palais/petit_palais_lanscape.jpg";

import img06 from "../../images/categories/grand-palais/canopy_sunrise.jpg";
import img07 from "../../images/categories/grand-palais/sico_canopy_sunrise.jpg";

export const categoryData = {
  src: img01,
  link: "grand-palais",
  title: "Grand Palais",
  description:
    "my first experience on monument rooftop, and maybe my favorite, we spend all night exploring the canopy",
  background: "#8EB19E",
  primary: "#1A1F22",
  secondary: "#fff",
  disabled: true, // for hidden categories

  themes: {
    yRange: [0, 150, 300],
    background: ["#18232C", "#18232C", "#7690A9"],
    textColor: ["#fff", "#fff", "#fff"],
  },
  categoryImg: { img: img01, location: "" },
  contents: [
    {
      img: img02,
      size: 4,
    },
    {
      img: img03,
      size: 4,
    },
    {
      img: img04,
      location: "the 3 Musketeers",
      size: 4,
    },
    {
      text: (
        <div className="text description">
          <p>
            Après avoir un bon moment dans le noir à découvrir les moindres
            recoins de cette immense verrière, nous appercevons enfin, aux
            environs de 3h30, les premières lueurs du soleil apparaissent au
            loin. Ce n'était que le début d'un spectacle fantastique.
          </p>
        </div>
      ),

      size: 2,
    },
    {
      img: img05,
      size: 8,
      position: 3,
    },
    {
      text: (
        <div className="text description">
          <p>
            Nous prenons un peu plus de hauteur encore lors du lever de soleil,
            une vue sublime sur tout Paris et ses monuments. Je me souviens
            m'être posé un instant afin de contempler le paysage. Je venais
            également de rencontrer deux superbes personnes, ayant de vraies
            valeurs.
          </p>
        </div>
      ),

      size: 2,
    },
    {
      img: img06,
      location: "sico",
      size: 4,
    },
    {
      text: (
        <div className="text description">
          <p>
            Par chance, le ciel était dégagé, les nuages reflétaient sur chacune
            des vitres. Le jour se lève, alors nous savons qu'il est bientôt
            l'heure pour nous de s'en aller. Tu sais, si l'on me demandait
            sunrise ou sunset, les gens auront tendance à répondre sunset car
            ils ont plus l'habitude d'en apercevoir, je vous répondrais sunrise
            sans hésitation. Un moment plus calme, souvent plus unique dû à nos
            aventures au-dessus de lieux insolites. <br />
            <br />
            Pendant que les gens dorment, nous vivons ...
          </p>
        </div>
      ),

      size: 4,
    },
    {
      img: img07,
      location: "sico faking taking some rest",
      size: 4,
    },
  ],
};
