import img01 from "../../images/categories/rooftops/paroisse_saint_jean_de_montmartre.jpg";
import img02 from "../../images/categories/rooftops/akela_rainbow.jpg";
import img03 from "../../images/categories/rooftops/roof_trinity.jpg";
import img04 from "../../images/categories/rooftops/sico_saint-paul-saint-louis.jpg";
import img05 from "../../images/categories/rooftops/sico_place_vendome.jpg";
import img06 from "../../images/categories/rooftops/kevin_champs-elysees.jpg";
import img07 from "../../images/categories/rooftops/akela_tours_pascal.jpg";
import img08 from "../../images/categories/rooftops/rooftops_kleber.jpg";
import img09 from "../../images/categories/rooftops/solenn_champs-elysees.jpg";
import img10 from "../../images/categories/rooftops/place_vendome_sunset.jpg";
import img11 from "../../images/categories/rooftops/noemie_montmartre.jpg";
// import img11 from "../../images/categories/rooftops/saint-gervais_church.jpg";
import img12 from "../../images/categories/rooftops/tours_pascal.jpg";
import img13 from "../../images/categories/rooftops/aurore_la_defense.jpg";
import img14 from "../../images/categories/rooftops/saint-gobain_crane.jpg";
import img15 from "../../images/categories/rooftops/sonik_rooftop.jpg";
// import img16 from "../../images/categories/rooftops/novotel_challenge.jpg";
import img16 from "../../images/categories/rooftops/novotel_challenge-1.jpg";
import img17 from "../../images/categories/rooftops/notre-dame-de-lorette.jpg";
import img18 from "../../images/categories/rooftops/ste_clothilde_church.jpg";
import img19 from "../../images/categories/rooftops/chartres_cathedral.jpg";
import img20 from "../../images/categories/rooftops/sico_palais_royal.jpg";
import img21 from "../../images/categories/rooftops/pullman_colin.jpg";
import img22 from "../../images/categories/rooftops/blue_firework.jpg";
import img23 from "../../images/categories/rooftops/green_firework.jpg";
import img24 from "../../images/categories/rooftops/purple_firework_2.jpg";

export const categoryData = {
  src: img01,
  link: "rooftops",
  title: "Rooftops",
  description: "Parisian rooftops, classic with some breathtaking views...",
  background: "#FFF7EE",
  primary: "#ED5115",
  secondary: "#000",
  disabled: true,
  themes: {
    yRange: [0, 130, 250],
    background: ["#DAB287", "#D2A07B", "#131418"],
    textColor: ["#000", "#000", "#fff"],
  },
  categoryImg: {
    img: img01,
    location: "taken by @iksus.kta - Église Saint-Jean de Montmartre",
  },
  contents: [
    {
      img: img02,
      location: "Toits de Paris - 2ème arondissement",
      size: 4,
    },
    {
      img: img03,
      location: "Tour Trinity - La Défense",
      size: 4,
    },
    {
      img: img04,
      location: "Paroisse Saint-Paul Saint-Louis - Paris",
      size: 4,
    },
    {
      img: img05,
      location: "Place vendome - Paris",
      size: 6,
    },
    {
      img: img06,
      location: "Champs Élysées - Paris",
      size: 6,
    },
    {
      img: img07,
      location: "Tours Pascal - La Défense",
      size: 6,
    },
    {
      img: img08,
      location: "Kléber - Paris",
      size: 6,
    },
    {
      img: img09,
      location: "Champs Élysées - Paris",
      size: 4,
    },
    {
      img: img10,
      location: "Place vendome - Paris",
      size: 4,
    },
    {
      img: img11,
      location: "Montmartre - Paris",
      size: 4,
    },
    {
      img: img12,
      location: "Tours Pascal - La Défense",
      size: 4,
    },

    {
      img: img13,
      location: "Tour Aurore - La Défense",
      size: 8,
    },
    {
      img: img14,
      location: "Grue Saint-Gobain - La Défense",
      size: 4,
    },
    {
      img: img15,
      location: "Noisy-Champs - Champs-sur-Marne",
      size: 4,
    },
    {
      img: img16,
      location: "Novotel - Paris",
      size: 4,
    },
    {
      img: img17,
      location: "Église Notre Dame de Lorette - Paris",
      size: 4,
    },
    {
      img: img18,
      location: "Église Ste Clothilde - Paris",
      size: 4,
    },
    {
      img: img19,
      location: "Cathédrale Notre-Dame de Chartres",
      size: 4,
    },
    {
      img: img20,
      location: "Palais Royal - Paris",
      size: 8,
    },
    {
      img: img21,
      location: "Montparnasse - Paris",
      size: 4,
    },
    {
      img: img22,
      location: "Champs de Mars - Paris",
      size: 4,
    },
    {
      img: img23,
      size: 4,
    },
    {
      img: img24,
      location: "Passy - Paris",
      size: 4,
    },
  ],
};
