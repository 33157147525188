import img01 from "../../images/categories/newYork/usa_digital_flag.jpg";

import img02 from "../../images/categories/newYork/statue_of_liberty.jpg";
import img03 from "../../images/categories/newYork/timesquare_by_day.jpg";
import img04 from "../../images/categories/newYork/rockefeller_plaza.jpg";
import img05 from "../../images/categories/newYork/timesquare_by_night.jpg";
import img06 from "../../images/categories/newYork/rainy_day.jpg";
import img07 from "../../images/categories/newYork/stone_guy_timesquare.jpg";
import centralParc from "../../images/categories/newYork/central_parc_from_top.jpg";
import img08 from "../../images/categories/newYork/timesquare_trafic.jpg";
import img09 from "../../images/categories/newYork/sport_car_timesquare.jpg";
import img10 from "../../images/categories/newYork/krispyKreme.jpg";
import img11 from "../../images/categories/newYork/empire_state_building_by_night.jpg";
import img12 from "../../images/categories/newYork/brooklyn_bridge_by_night.jpg";
import img13 from "../../images/categories/newYork/one_world_trade_center.jpg";
import img14 from "../../images/categories/newYork/spider_man_brooklyn_bridge.jpg";
import img15 from "../../images/categories/newYork/hot_dogs_stand.jpg";
import img16 from "../../images/categories/newYork/m&ms.jpg";
import img17 from "../../images/categories/newYork/flatiron_building.jpg";
import img18 from "../../images/categories/newYork/buildings&birds.jpg";
import img19 from "../../images/categories/newYork/manhattan_from_ferry.jpg";
import img20 from "../../images/categories/newYork/mom_coney_island.jpg";

export const categoryData = {
  src: img01,
  link: "new-york",
  title: "New York",
  description: "the big apple",
  background: "#F04630",
  primary: "#6A89E6",
  secondary: "#fff",

  categoryImg: { img: img01 },
  themes: {
    yRange: [0, 75, 120, 200],
    background: ["#F21307", "#fff", "#0C61EA", "#031317"],
    textColor: ["#fff", "#174B99", "#fff", "#fff"],
  },
  contents: [
    {
      text: (
        <div className="text description text-align-center">
          {/* french version */}
          {/* Depuis mes 13 ans, mon objectif est celui de partir vivre aux États
          Unis, pas accomplit pour l'instant mais on travaille dessus.
          <br />
          Première étape franchie, celle de visiter cette ville qui me fait tant
          rêver, que l'on voit dans tout les films, celle dont tout le monde
          parle. Celle qui brille autant de jour que de nuit... New York */}
          {/* english version */}
          Since I was 13, my goal has been to go and live in the United States,
          not accomplished yet but we are working on it.
          <br />
          First step taken, that of visiting this city that makes me dream so
          much, that we see in all the films, the one that everyone is talking
          about. The one that shines as much by day as by night... New York
        </div>
      ),

      size: 12,
    },
    {
      img: img02,
      position: 2,
      size: 10,
    },
    {
      img: centralParc,
      position: 2,
      size: 10,
    },

    {
      img: img03,
      size: 5,
    },
    {
      img: img04,
      size: 5,
      position: 8,
    },

    {
      img: img05,
      size: 5,
      position: 2,
    },
    {
      img: img06,
      size: 5,
      position: 7,
    },

    {
      img: img07,
      size: 4,
      position: 2,
    },
    {
      text: (
        <div className="text description text-align-left">
          {/* french version */}
          {/* 5h30 du matin, il restait là, figé, le regard vide */}
          {/* english version */}
          5:30 in the morning, he stood there, frozen, staring blankly, like a
          zombie
        </div>
      ),
      size: 2,
    },
    {
      img: img08,
      size: 4,
      position: 8,
    },

    {
      img: img09,
      size: 5,
    },
    {
      text: (
        <div className="text description">
          {/* french version */}
          {/* Cette ville est un film */}
          {/* english version */}
          this city is a movie
        </div>
      ),

      size: 2,
    },
    {
      img: img10,
      size: 5,
      position: 8,
    },

    {
      img: img11,
      size: 6,
      position: 4,
    },

    {
      img: img12,
      size: 4,
    },
    {
      img: img13,
      size: 4,
    },
    {
      img: img14,
      size: 4,
    },

    {
      img: img15,
      size: 5,
    },
    {
      img: img16,
      size: 5,
      position: 8,
    },
    {
      img: img17,
      size: 5,
      position: 2,
    },
    {
      img: img18,
      size: 5,
      position: 7,
    },

    {
      img: img19,
      size: 10,
      position: 2,
    },

    {
      img: img20,
      size: 4,
      position: 2,
    },
    {
      text: (
        <>
          <div className="text description italic ">
            Moi:
            <br />- "Alors New York c'était comment ?"
            <br />
            <br />
            <br />
            Maman:
            <br />- "Grandiose, c'était comme dans les jeux vidéos, une partie
            dans un jeu de zombie. C'est la jungle New York. <br />
            C'était cool quand même New York... Ouais ouais franchement c'était
            cool"
          </div>
        </>
      ),
      size: 6,
      position: 6,
    },
  ],
};
