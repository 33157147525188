import { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useGlobalStateContext } from "../context/globalContext";
import { AnimatePresence, motion } from "framer-motion";

import Home from "./home";

import { activeCategories } from "../assets/data/categories";
import MainSection from "./categories/mainSection";

const Gallery = () => {
  const { homeLoaded, categoryLoaded } = useGlobalStateContext();
  const params = useParams();
  const [categoryName, setCategoryName] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setTimeout to check if bugs disapears

    if (params.category) {
      setCategoryName(params.category);
    }
    if (!params.category) {
      setCategoryName(null);
    }
    if ((params.category && homeLoaded) || (!params.category && !homeLoaded)) {
      setLoading(true);
    }
    if ((params.category && !homeLoaded) || (!params.category && homeLoaded)) {
      setLoading(false);
    }
  }, [params, homeLoaded]);

  const renderMainSection = (categoryName) => {
    const selectedCategory = activeCategories.find(
      (category) => category.link === categoryName
    );

    // ***** EASTER EGG ***** //
    if (
      selectedCategory === undefined ||
      selectedCategory?.comingSoon
      // || (selectedCategory?.disabled &&
      //   localStorage.getItem("unlock-disabled-categories") !== "true")
    ) {
      return <Redirect to="/gallery" />;
    }
    const { link, themes, categoryImg, contents } = selectedCategory;

    return (
      <MainSection
        categoryName={link}
        key={link}
        themes={themes}
        categoryImg={categoryImg}
        contents={contents}
      />
    );
  };

  return (
    <>
      <AnimatePresence>
        {loading && <Loading key="loading" />}
        {(!params.category || homeLoaded) && <Home key="home" />}
        {(params.category || categoryLoaded) &&
          categoryName &&
          renderMainSection(categoryName)}
      </AnimatePresence>
    </>
  );
};

export default Gallery;

const Loading = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 1 } }}
      exit={{ opacity: 0 }}
      id="gallery-loader"
    >
      <div className="loading-text">
        <span className="loading-text-words">L</span>
        <span className="loading-text-words">O</span>
        <span className="loading-text-words">A</span>
        <span className="loading-text-words">D</span>
        <span className="loading-text-words">I</span>
        <span className="loading-text-words">N</span>
        <span className="loading-text-words">G</span>
      </div>
    </motion.div>
  );
};
