import * as THREE from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import { Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";

import "./shaders/imageMaterial";
import gsap, { TweenLite } from "gsap";
import {
  activeCategories,
  comingSoonCategory,
} from "../assets/data/categories";

const CanvasScene = ({
  homeLoaded,
  scrollPos,
  attractMode,
  animated,
  setAnimated,
  selectedCategoryIndex,
  displayType,
  setDisplayType,
  dispatch,
  onCursor,
}) => {
  const [groupPosition, setGroupPosition] = useState([0.8, 0, 0]);
  const [groupRotation, setGroupRotation] = useState([-0.3, -0.4, -0.2]);
  const [isAnimating, setIsAnimating] = useState(false);
  const groupRef = useRef();
  const loadedCount = useRef(0);

  const onResize = () => {
    if (window.innerWidth <= 1024) {
      setGroupRotation([0, 0, 0]);
      setGroupPosition([0, 0, 0]);
    } else {
      setGroupRotation([-0.3, -0.4, -0.2]);
      setGroupPosition([0.8, 0, 0]);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    if (selectedCategoryIndex !== null && homeLoaded) {
      setTimeout(() => {
        goBackAnimation(selectedCategoryIndex);
      }, 1000);
    }
  }, [homeLoaded]);

  useEffect(() => {
    if (animated !== null && selectedCategoryIndex === null) {
      animate(animated);
    }
  }, [animated]);

  const goBackAnimation = (id) => {
    setIsAnimating(true);
    setViewSize(id);
    groupRef.current.children[id].material.uniforms.uProgress.value = 1;
    groupRef.current.position.x = 0;
    groupRef.current.rotation.set(0, 0, 0);
    moveInBackground(id, 0);

    setTimeout(() => {
      if (window.innerWidth > 1024) {
        gsap.to(groupRef.current.position, {
          duration: 0.5,
          x: 0.8,
        });
        gsap.to(groupRef.current.rotation, {
          duration: 0.5,
          x: -0.3,
          y: -0.4,
          z: -0.2,
        });
      }

      moveInForeground(id);
      TweenLite.to(
        groupRef.current.children[id].material.uniforms.uProgress,
        1,
        {
          value: 0,
          onComplete: () => {
            setIsAnimating(false);
            setAnimated(null);
            dispatch({
              type: "SELECTED_CATEGORY_INDEX",
              selectedCategoryIndex: null,
            });
          },
        }
      );
    }, 1000);
  };

  const onLoad = () => {
    loadedCount.current += 1;

    if (loadedCount.current === activeCategories.length) {
      dispatch({ type: "GALLERY_LOADED", homeLoaded: true, categories: false });
    }
  };

  const toFullscreen = (id, duration = 1) => {
    if (displayType === "fullscreen" || isAnimating) return;
    setIsAnimating(true);

    TweenLite.to(
      groupRef.current.children[id].material.uniforms.uProgress,
      duration,
      {
        value: 1,
        onComplete: () => {
          setDisplayType("fullscreen");
          setIsAnimating(false);
        },
      }
    );
  };
  const toGrid = (id, duration = 1) => {
    if (displayType === "grid" || isAnimating) return;
    setIsAnimating(true);
    TweenLite.to(
      groupRef.current.children[id].material.uniforms.uProgress,
      duration,
      {
        value: 0,
        onComplete: () => {
          setDisplayType("grid");
          setIsAnimating(false);
        },
      }
    );
  };

  const moveInBackground = (id, duration = 1) => {
    groupRef.current.children.map((elem, i) => {
      if (i !== id) {
        TweenLite.to(elem.material.uniforms.hidden, duration, {
          value: 1,
        });
        const saveZpos = elem.position.z;
        gsap.to(elem.position, {
          duration: 0.5,
          z: saveZpos - 0.2,
        });
      }
    });
  };

  const moveInForeground = (id, duration = 1) => {
    groupRef.current.children.map((elem, i) => {
      if (i !== id) {
        TweenLite.to(elem.material.uniforms.hidden, duration, {
          value: 0,
        });
        const saveZpos = elem.position.z;
        gsap.to(elem.position, {
          duration: 0.5,
          z: saveZpos + 0.2,
        });
      }
    });
  };

  const getViewSize = () => {
    const camera = {
      fov: 60,
      position: { x: 0, y: 0, z: 2.3 },
      aspect: window.innerWidth / window.innerHeight,
    };
    const fovInRadians = (camera.fov * Math.PI) / 180;
    const height = Math.abs(camera.position.z * Math.tan(fovInRadians / 2) * 2);

    return { width: height * camera.aspect, height };
  };

  const setViewSize = (id) => {
    const viewSize = getViewSize();

    groupRef.current.children[id].material.uniforms.uScaleToViewSize.value.x =
      viewSize.width / 1.5 - 1;
    groupRef.current.children[id].material.uniforms.uScaleToViewSize.value.y =
      viewSize.height - 1;
  };

  const animate = (id) => {
    setViewSize(id);

    if (displayType === "grid" && !isAnimating) {
      gsap.to(groupRef.current.position, {
        duration: 0.5,
        x: 0,
      });
      gsap.to(groupRef.current.rotation, {
        duration: 0.5,
        x: 0,
        y: 0,
        z: 0,
      });

      moveInBackground(id);
      toFullscreen(id);
    } else if (displayType === "fullscreen" && !isAnimating) {
      gsap.to(groupRef.current.position, {
        duration: 0.5,
        x: 0.8,
      });
      gsap.to(groupRef.current.rotation, {
        duration: 0.5,
        x: -0.3,
        y: -0.4,
        z: -0.2,
      });

      moveInForeground(id);
      toGrid(id);
    }
  };

  useEffect(() => {
    if (groupRef.current) {
      if (attractMode) {
        gsap.to(groupRef.current.position, {
          duration: 0.5,
          x: 0,
        });
        gsap.to(groupRef.current.rotation, {
          duration: 0.5,
          x: -0.6,
          y: 0,
          z: 0,
        });
      } else {
        gsap.to(groupRef.current.position, {
          duration: 0.5,
          x: 0.8,
        });
        gsap.to(groupRef.current.rotation, {
          duration: 0.5,
          x: -0.3,
          y: -0.4,
          z: -0.2,
        });
      }
    }
  }, [attractMode]);

  return (
    <Suspense fallback={null}>
      <group ref={groupRef} position={groupPosition} rotation={groupRotation}>
        {activeCategories.map((elem, i) => {
          const category = elem.comingSoon ? comingSoonCategory : { ...elem };

          if (category.link) {
            return (
              <Plane
                key={i}
                onLoad={onLoad}
                scrollPos={scrollPos}
                onPointerEnter={() => onCursor("hovered")}
                onPointerLeave={onCursor}
                onClick={() => setAnimated(i)}
                i={i}
                img={category.src}
              />
            );
          } else {
            return (
              <Plane
                key={i}
                onLoad={onLoad}
                scrollPos={scrollPos}
                i={i}
                img={category.src}
              />
            );
          }
        })}
      </group>
    </Suspense>
  );
};

export default CanvasScene;

const Plane = ({ onLoad, onPointerEnter, onPointerLeave, ...props }) => {
  const texture1 = useLoader(THREE.TextureLoader, props.img);
  useEffect(() => {
    if (texture1) onLoad();
  }, [texture1]);

  const uniforms = useRef({
    uScaleToViewSize: new THREE.Vector2(1, 1),
    uProgress: { value: 0 },
    distanceFromCenter: 0,
    // uZMax: new THREE.Uniform(1),
  });
  const matRef = useRef();
  const meshRef = useRef();

  useFrame(() => (matRef.current.time += 0.05));

  useFrame(() => {
    uniforms.current.distanceFromCenter = Math.min(
      Math.abs(props.scrollPos - props.i),
      1
    );
    uniforms.current.distanceFromCenter =
      1 - uniforms.current.distanceFromCenter ** 2;
    let scale =
      1 +
      0.1 *
        uniforms.current.distanceFromCenter *
        (1 - meshRef.current.material.uniforms.uProgress.value);

    meshRef.current.position.y = -props.i * 1.2 + props.scrollPos * 1.2;

    meshRef.current.scale.set(scale, scale, scale);
  });

  return (
    <mesh
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onClick={props.onClick}
      // {...props}
      ref={meshRef}
    >
      <planeBufferGeometry args={[1.5, 1, 20, 20]} />
      <imageMaterial
        ref={matRef}
        attach="material"
        uProgress={uniforms.current.uProgress.value}
        texture1={texture1}
        uScaleToViewSize={uniforms.current.uScaleToViewSize}
        distanceFromCenter={uniforms.current.distanceFromCenter}
        u_textureFactor={new THREE.Vector2(1.5, 1)}
        uMeshScale={new THREE.Vector2(1.5, 1)}
      />
    </mesh>
  );
};
