import { extend } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";
import * as THREE from "three";

const ImageMaterial = shaderMaterial(
  {
    // side: THREE.DoubleSide,
    texture1: null,
    transparent: true,
    vertexColors: true,
    time: 0,
    distanceFromCenter: 1,
    hidden: 0,
    uProgress: 0,
    uScaleToViewSize: new THREE.Vector2(1, 1),
    u_textureFactor: new THREE.Vector2(1.5, 1),
    uMeshScale: new THREE.Vector2(1, 1),
  },
  `varying vec2 vUv;
  uniform float time;
  uniform float distanceFromCenter;
  uniform float uProgress;
  uniform vec2 uScaleToViewSize;

  varying float vProgress;
  varying vec2 scale;

  float PI = 3.14159265359;

  void main() {
    
    vec3 pos = position;
    vec3 pos1 = position.xyz;

    // Activation for left-to-right
		float activation = uv.x;

    float latestStart = .5;
		float startAt = activation * latestStart;

    float vertexProgress = smoothstep(startAt,1.,uProgress);

    scale = vec2(1. + uScaleToViewSize * vertexProgress);
    pos.xy *=  scale;


    // vUv = (uv - vec2(0.5))*(.9 - 0.2 * distanceFromCenter * (1.5 - distanceFromCenter)) + vec2(0.5);
    vec2 scaleEffect = vec2((.9 + (.1 * vertexProgress)) - (0.2 * distanceFromCenter * (1.5 - distanceFromCenter)) * (1. - vertexProgress));
    vUv =  (uv - vec2(0.5))*scaleEffect + vec2(0.5);
    // vec2 calc = (uv - vec2(0.5))*(.9 - (0.2 * distanceFromCenter * (1.5 - distanceFromCenter)) + vec2(0.5);
    // vUv = clamp(vertexProgress,uv,calc);
    // vUv = uv;

    pos.y += sin(PI*uv.x)*(0.01 * (1. -vertexProgress));
    pos.z += sin(PI*uv.x)*(0.02 * (1. - vertexProgress));
    pos.y += sin(time*0.3)* (0.02 * (1. - vertexProgress));
    vUv.y -= sin(time*0.3)*(0.02 * (1. - vertexProgress));
    gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1. );
    vProgress = vertexProgress;
  }`,
  `
  varying vec2 vUv;
  varying vec2 scale;
  varying float vProgress;
  uniform float distanceFromCenter;
  uniform float hidden;
  uniform sampler2D texture1;
  uniform vec2 u_textureFactor;
  uniform float uProgress;
  uniform vec2 uMeshScale;

  vec2 centeredAspectRatio(vec2 uvs, vec2 factor){
    return uvs * factor - factor /2. + .5;
  }

  vec2 preserveAspectRatioSlice(vec2 uv, vec2 planeSize, vec2 imageSize ){
      
    vec2 ratio = vec2(
        min((planeSize.x / planeSize.y) / (imageSize.x / imageSize.y), 1.0),
        min((planeSize.y / planeSize.x) / (imageSize.y / imageSize.x), 1.0)
    );
    
    
    vec2 sliceUvs = vec2(
        uv.x * ratio.x + (1.0 - ratio.x) * 0.5,
        uv.y * ratio.y + (1.0 - ratio.y) * 0.5
    );

    return sliceUvs;
}
  
  void main() {

    vec2 uv = vUv;

    vec2 scaledPlane = uMeshScale * scale;

    vec4 t = texture2D(texture1, vUv);
  
    float bw = (t.r + t.b + t.g)/3.;
    vec4 another = vec4(bw,bw,bw,1.);

    vec2 smallImageUV = preserveAspectRatioSlice(uv, scaledPlane, u_textureFactor);
    vec3 color = texture2D(texture1,smallImageUV).xyz;
    // if(vProgress > 0.){
    //   vec2 largeImageUV = preserveAspectRatioSlice(uv, scaledPlane, u_textureFactor);
    //   color = mix(color,texture2D(texture1,largeImageUV).xyz, vProgress );
    // }

    color = mix(another.xyz,color,distanceFromCenter);

    gl_FragColor = vec4(color,1.);
    // gl_FragColor.a = clamp(distanceFromCenter,0.2,1.);
    float hiddenClamp = 0.2 * (1. - hidden);
    gl_FragColor.a = clamp(distanceFromCenter,0.2,1.);

  }`
);

extend({ ImageMaterial });
