import img01 from "../../images/categories/blackForest/train_ravenna_bridge.jpg";
import img02 from "../../images/categories/blackForest/wildsee_hut.jpg";

import img03 from "../../images/categories/blackForest/nissan_juke_fog.jpg";
import img04 from "../../images/categories/blackForest/lighting_in_the_fog.jpg";
import img05 from "../../images/categories/blackForest/wildsee_motel_lake.jpg";
import img06 from "../../images/categories/blackForest/wildsee_motel.jpg";
import img07 from "../../images/categories/blackForest/forest&fog.jpg";
import img08 from "../../images/categories/blackForest/colorful_forest.jpg";
import img09 from "../../images/categories/blackForest/longexposure_river.jpg";
import img10 from "../../images/categories/blackForest/waterfall& raindrops.jpg";
import img11 from "../../images/categories/blackForest/red_squirrel.jpg";

export const categoryData = {
  src: img01,
  link: "black-forest",
  title: "Black Forest",
  description: "First time I saw such an atmosphere. Fog, clouds and dark...",
  background: "#060607",
  primary: "#FDD9B8",
  secondary: "#fff",
  categoryImg: { img: img01, location: "" },
  themes: {
    yRange: [0, 100],
    background: ["#060709", "#0E1116"],
    textColor: ["#fff", "#fff"],
  },
  contents: [
    {
      img: img02,
      location: "Mummelsee",
      size: 6,
    },
    {
      img: img03,
      size: 3,
    },
    {
      img: img04,
      size: 3,
    },
    {
      img: img05,
      location: "Mummelsee",
      size: 3,
    },
    {
      img: img06,
      size: 3,
    },
    {
      img: img07,
      size: 6,
    },
    {
      img: img08,
      size: 8,
    },
    {
      img: img09,
      location: "Triberg Waterfalls",
      size: 4,
    },
    {
      img: img10,
      location: "Triberg Waterfalls",
      size: 4,
    },
    {
      img: img11,
      location: "Zauberwald",
      size: 8,
    },
  ],
};
