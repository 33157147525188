import img01 from "../../images/categories/summer/dune_pilat_landscape.jpeg";
import img02 from "../../images/categories/summer/dune_pilat_forest.jpg";
import img03 from "../../images/categories/summer/portrait_pilat.jpg";
import img04 from "../../images/categories/summer/feets_sand.jpg";
import img05 from "../../images/categories/summer/bird_pilat.jpg";
import img06 from "../../images/categories/summer/sunset_beach.jpg";
import img07 from "../../images/categories/summer/sunset_sand.jpg";
import img08 from "../../images/categories/summer/yacht_in_the_sea.jpg";
import img09 from "../../images/categories/summer/bonifacio_city.jpg";
import img10 from "../../images/categories/summer/yacht_moon_sunset.jpg";
import img11 from "../../images/categories/summer/yacht_leaving_during_sunset.jpg";

export const categoryData = {
  src: img01,
  link: "summer",
  title: "SummerScape",
  description:
    "Some pictures that remind you that summer is still the best season",
  background: "#C39263",
  primary: "#45586B",
  secondary: "#000",
  categoryImg: {
    img: img01,
    location: "Dune du pilat - La Teste-de-Buch",
  },
  themes: {
    textColor: ["#000", "#000", "#fff"],
    yRange: [0, 100, 180],
    background: ["#AB8160", "#AB8160", "#68818E"],
  },
  contents: [
    {
      img: img02,
      location: "Dune du pilat - La Teste-de-Buch",
      size: 3,
    },
    {
      img: img03,
      size: 3,
    },

    {
      img: img04,
      size: 3,
    },
    {
      img: img05,
      size: 3,
    },
    {
      img: img06,
      location: "Biscarrosse beach",
      size: 3,
    },
    {
      img: img07,
      size: 3,
    },
    {
      img: img08,
      location: "Bonifacio - Corsica",
      size: 6,
    },
    {
      img: img09,
      location: "Bonifacio - Corsica",
      size: 6,
    },
    {
      img: img10,
      size: 3,
    },
    {
      img: img11,
      size: 3,
    },
  ],
};
