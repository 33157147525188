import { useEffect, useRef, useState } from "react";

import { motion } from "framer-motion";
import ScrollComponent from "../ScrollComponent";
import { useHistory, useLocation } from "react-router";
import ScrollReveal from "../ScrollReveal";
import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from "../../context/globalContext";
import { convertVhToPx } from "../../utils/math";
import { isMobile } from "react-device-detect";
import { activeCategories } from "../../assets/data/categories";

const TABLET_WIDTH = 768;
const vhValue = convertVhToPx(8);

const MainSection = ({ categoryImg, themes, contents, categoryName }) => {
  const { pathname } = useLocation();
  const scroll = useRef();
  const maxCount = useRef(null);
  const loadedCount = useRef(0);
  const { cursorStyles, categoryLoaded } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();
  let history = useHistory();

  const calcMaxCount = () => {
    let count = 0;
    contents.map((content) => {
      if (content.img) count++;
    });

    return count;
  };

  const onLoad = () => {
    loadedCount.current += 1;
    if (maxCount.current && loadedCount.current === maxCount.current + 1) {
      dispatch({
        type: "GALLERY_LOADED",
        categoryLoaded: true,
        homeLoaded: false,
      });
    }
  };

  useEffect(() => {
    maxCount.current = calcMaxCount();

    dispatch({ type: "CURSOR_TYPE", cursorType: null });
  }, []);

  const goBack = () => {
    if (
      history.location.pathname !== "/gallery" ||
      history.location.pathname !== "/gallery/"
    ) {
      scroll.current.scrollTo("top", { duration: 100 });
      setTimeout(() => {
        dispatch({
          type: "SELECTED_CATEGORY_INDEX",
          selectedCategoryIndex: activeCategories.findIndex(
            (category) => category.link === categoryName
          ),
        });
        history.push("/gallery");
      }, 600);
    }
  };

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: "CURSOR_TYPE", cursorType: cursorType });
  };

  const onMouseEnter = () => {
    onCursor("back");
  };

  const onMouseLeave = () => {
    onCursor();
  };

  return (
    <ScrollComponent
      loaded={categoryLoaded}
      scroll={scroll}
      id={`${pathname.slice(1, pathname.length)}-category`}
      className="category-page"
      themes={themes}
    >
      <BackBtn
        loaded={categoryLoaded}
        goBack={goBack}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />

      <div className="category-img">
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ delay: 1, duration: 1.4 }}
          animate={{ opacity: categoryLoaded ? 1 : 0 }}
          id="scroll-down"
        >
          <span className="arrow-down"></span>
          <span id="scroll-title">Scroll down</span>
        </motion.div>

        <img onLoad={onLoad} src={categoryImg.img} />
        <p className="description">{categoryImg.location}</p>
      </div>

      <div className="pictures-grid">
        {contents.map((content, i) => (
          <Picture content={content} key={i} onLoad={onLoad} />
        ))}
      </div>
    </ScrollComponent>
  );
};

export default MainSection;

const Picture = ({ content, onLoad }) => {
  const ref = useRef();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseMove = (e) => {
    const position = ref.current.getBoundingClientRect();
    const x = (e.pageX - position.left - position.width / 2) * 0.3;
    const y = (e.pageY - position.top - position.height / 2) * 0.3;

    setPosition({ x, y });
  };

  const onMouseOut = (e) => {
    setHovered(false);
    setPosition({ x: 0, y: 0 });
  };

  const gridCol = (_content) => {
    const start = _content.position ? _content.position : "auto";
    const end = _content.size;

    return `grid-col-start-${start} grid-col-end-${end}`;
  };

  return (
    <ScrollReveal className={`grid-section ${gridCol(content)}`}>
      {(!isMobile || window.innerWidth > TABLET_WIDTH) && (
        <div
          onMouseEnter={onMouseEnter}
          onMouseMove={onMouseMove}
          onMouseOut={onMouseOut}
          // onClick={() => setClicked(!clicked)}
          className="magnet-area"
        ></div>
      )}

      <motion.div
        animate={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          scale: hovered ? 0.95 : 1,
        }}
        transition={{ ease: "linear" }}
        ref={ref}
        className={`picture`}
      >
        {content.img ? (
          <img
            onLoad={onLoad}
            className={clicked ? "animate" : ""}
            src={content.img}
          />
        ) : (
          content.text
        )}
        {content.location && (
          <span className="location">{content.location}</span>
        )}
      </motion.div>
    </ScrollReveal>
  );
};

const BackBtn = ({ loaded, goBack, onMouseEnter, onMouseLeave }) => {
  const ref = useRef();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const onMouseMove = (e) => {
    const position = ref.current.getBoundingClientRect();
    const x = (e.pageX - position.left - position.width / 2) * 0.3;
    const y = (e.pageY - position.top - position.height / 2) * 0.6;
    setPosition({ x, y });
  };

  return (
    <motion.div
      initial={{ opacity: 0, top: 20 }}
      transition={{ duration: 0.4, delay: 1 }}
      animate={{ opacity: loaded ? 1 : 0, top: vhValue }}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={goBack}
      className="back-btn"
    >
      <motion.span
        transition={{ ease: "linear" }}
        animate={{
          left: `${position.x}px`,
          top: `${position.y}px`,
        }}
        onMouseMove={onMouseMove}
        onMouseLeave={() => setPosition({ x: 0, y: 0 })}
      >
        BACK
      </motion.span>
    </motion.div>
  );
};
