import img01 from "../../images/categories/moon/radiotelescopes_sunset_landscape.jpg";
import img02 from "../../images/categories/moon/sico_walking_3_radiotelescopes.jpg";
import img03 from "../../images/categories/moon/sunset_contrasts.jpg";
import img04 from "../../images/categories/moon/stars_radiotelescope.jpg";
import img05 from "../../images/categories/moon/sico_milkyway_radiotelescope.jpg";
import img06 from "../../images/categories/moon/sico_standing_on_the_top.jpg";
import img07 from "../../images/categories/moon/melissa_cloud_sea.jpg";
import img08 from "../../images/categories/moon/sunset_cloud_sea.jpg";

export const categoryData = {
  src: img01,
  link: "moon",
  title: "Moon",
  description: "secret place, great moment",
  background: "#0A0709",
  primary: "#5F638C",
  secondary: "#E8BC92",

  categoryImg: { img: img01, location: "... . -.-. .-. . -" },
  themes: {
    yRange: [0, 250, 350, 450],
    background: ["#465178", "#0D0702", "#0D0702", "#7BAFC9"],
    textColor: ["#fff", "#fff", "#fff", "#fff"],
  },
  contents: [
    {
      text: (
        <div className="text description text-align-center">
          {/* french version */}
          {/* Je vous passe l'aventure que ce fut, ne serait-ce que pour rejoindre
          cet endroit, une sacrée épopée...
          <br />
          ça aura demandé du temps, et de traverser dans un premier temps toute
          la france, comme on a pu. Mais le resultat en valait les efforts,
          <br />
          puis entre bonnes personnes, on en retient souvent de bons moments */}
          {/* english version */}
          I'll spare you the adventure it was, if only to reach this place,
          quite an epic...
          <br />
          it will have taken time, and to first cross all of France, as best we
          could. But the result was worth the effort.
          <br />
          then between good people, we often remember good times
        </div>
      ),

      size: 12,
    },
    {
      img: img02,
      location: "Romain",
      size: 5,
    },
    {
      text: (
        <div className="text description text-align-center">
          {/* french version */}
          {/* On a eu là plusieurs ambiances différentes, tout à défilé si vite, le
          brouillard est passé, puis un sunset éclatant, s'en est allé ensuite
          dans des tons plus doux */}
          {/* english version */}
          We had several different moods, everything went by so quickly, the fog
          passed, then a brilliant sunset, then went away in softer tones
        </div>
      ),

      size: 2,
    },
    {
      img: img03,
      size: 5,
    },
    {
      text: (
        <div className="text description text-align-center">
          {/* french version */}
          {/* Nous partons dormir, il faut savoir qu'ici, tout est rocheux, pas
          d'herbe ou de terre pour s'y reposer dessus, seulement des cailloux,
          <br />
          Mais nous avions trouvé un peu plus tôt notre spot secret, à l'arbit
          du vent, et sans risque de se retrouver avec un rocher enfoncé dans le
          dos au réveil
          <br />
          Il était maintenant temps d'aller dormir, du moins essayer, en
          attendant les étoiles, mais surtout que la voie lactée soit moins
          timide */}
          {/* english version */}
          We go to sleep, you have to know that here, everything is rocky, no
          grass or earth to rest on, only pebbles,
          <br />
          But we had found our secret spot a little earlier, at the arbit of the
          wind, and without the risk of ending up with a rock stuck in our back
          when we woke up.
          <br />
          It was now time to go to sleep, at least try, while waiting for the
          stars, but above all for the Milky Way to be less shy
        </div>
      ),
      size: 12,
    },
    {
      text: (
        <div className="text description text-align-right">
          {/* french version */}
          {/* Il faisait froid, très froid, mais il était l'heure. Curieux de
          découvrir le ciel.
          <br />
          Nous voilà avec appareils et trépieds en main en direction du meilleur
          spot pour vous faire des bangerz
          <br />
          <br />
          Puis à ce moment là on découvre la voie lactée tout juste au dessus de
          ces immenses paraboles */}
          {/* english version */}
          It was cold, very cold, but it was time. Curious to discover the sky.
          <br />
          Here we are with cameras and tripods in hand in the direction of the
          best spot to bring you back some bangerz
          <br />
          <br />
          Then at that moment we discover the Milky Way just above these huge
          parabolas
        </div>
      ),
      size: 2,
    },
    {
      img: img04,
      size: 5,
    },

    {
      img: img05,
      size: 5,
      location: "photoshop for the grass :P",
    },
    {
      text: (
        <div className="text description">
          {/* french version */}
          {/* La nuit est terminée et nous sommes réveillés par une discussions
          juste au dessus de nos têtes, il est temps pour nous de s'en aller. */}
          {/* english version */}
          The night is over and we are woken up by a discussion right above our
          heads, it is time for us to leave.
        </div>
      ),
      size: 12,
    },
    {
      img: img06,
      location: "Romain",
      size: 7,
    },
    {
      img: img07,
      location: "Mélissa",
      size: 5,
    },
    {
      img: img08,
      size: 12,
    },
    {
      text: (
        <div className="text description">
          {/* french version */}
          {/* Merci pour ces bons moments */}
          {/* english version */}~ Thank you for these good times ~
        </div>
      ),
      size: 12,
    },
  ],
};
