import img01 from "../../images/categories/mountain/colin-portrait-mountain.jpeg";
import img02 from "../../images/categories/mountain/colin_take_the_sunrise.jpg";
import img03 from "../../images/categories/mountain/sunrise_landscapes.jpg";
import img04 from "../../images/categories/mountain/sun_in_my_hand.jpg";
import img05 from "../../images/categories/mountain/contrast_colors_distance.jpg";
import img06 from "../../images/categories/mountain/lac_de_peyreguet.jpg";
import img07 from "../../images/categories/mountain/tent&midi_d_ossau.jpg";
import img08 from "../../images/categories/mountain/pic_du_midi_contrast.jpg";
import img09 from "../../images/categories/mountain/faustin_walk_sheeps.jpg";
import img10 from "../../images/categories/mountain/opel_car.jpg";
import img11 from "../../images/categories/mountain/zigzag_path.jpg";
import img12 from "../../images/categories/mountain/car_forest_road.jpg";
import img13 from "../../images/categories/mountain/cow_portrait.jpg";

import img14 from "../../images/categories/mountain/faustin_river.jpg";
import img15 from "../../images/categories/mountain/fir_trees_landscape.jpg";
import img16 from "../../images/categories/mountain/faustin_lake.jpg";

import img17 from "../../images/categories/mountain/grandpa_in_fog.jpg";
import img18 from "../../images/categories/mountain/mountain_close_up.jpg";
import img19 from "../../images/categories/mountain/laruns_village.jpg";

import img20 from "../../images/categories/mountain/fire&chamallow.jpg";
import img21 from "../../images/categories/mountain/firecamp&tent.jpg";

import img22 from "../../images/categories/mountain/sea_of_clouds.jpg";
import img23 from "../../images/categories/mountain/milky_way&tent.jpg";
import img24 from "../../images/categories/mountain/tent&stars.jpg";

export const categoryData = {
  src: img01,
  link: "mountain",
  title: "Mountain",
  description:
    "When I was young I hated going to the mountains, but now I missed it",
  background: "#84A2BF",
  primary: "#E9DABE",
  secondary: "#fff",
  categoryImg: {
    img: img01,
    location: "taken by @compagnefaustin",
  },
  themes: {
    yRange: [0, 500, 550],
    background: ["#fff", "#fff", "#000"],
    textColor: ["#000", "#000", "#fff"],
  },
  contents: [
    {
      img: img02,
      location: "Lac Roumassot - Laruns",
      size: 3,
    },
    {
      img: img03,
      size: 6,
    },
    {
      img: img04,
      size: 3,
    },
    {
      img: img05,
      location: "Lac Roumassot - Laruns",
      size: 4,
    },
    {
      img: img06,
      location: "Lac de Peyreget - Laruns",
      size: 4,
    },
    {
      img: img07,
      location: "Lac Roumassot - Laruns",
      size: 4,
    },
    {
      img: img08,
      location: "Pic du Midi d'Ossau - Laruns",
      size: 6,
    },
    {
      img: img09,
      size: 6,
    },
    {
      img: img10,
      location: "Col d'Aubisque - Béost",
      size: 3,
    },
    {
      img: img11,
      size: 3,
    },
    {
      img: img12,
      location: "Laruns - Pyrénées",
      size: 3,
    },
    {
      img: img13,
      location: "Pic du Midi d'Ossau - Laruns",
      size: 3,
    },

    {
      img: img14,
      location: "Pic du Midi d'Ossau - Laruns",
      size: 3,
    },
    {
      img: img15,
      location: "Eaux-Bonnes - Pyrénées",
      size: 6,
    },
    {
      img: img16,
      location: "Laruns - Pyrénées",
      size: 3,
    },
    //
    {
      img: img17,
      location: "Col d'Aubisque - Béost",
      size: 3,
    },
    {
      img: img18,
      location: "Laruns - Pyrénées",
      size: 6,
    },
    {
      img: img19,
      size: 3,
    },
    //
    {
      img: img20,
      location: "Lac Roumassot - Laruns",
      size: 4,
    },
    {
      img: img21,
      size: 4,
    },
    {
      text: (
        <div
          style={{
            marginBottom: "3rem",
          }}
          className="text big"
        >{`ASTRO⇩`}</div>
      ),
      size: 4,
    },
    //
    {
      img: img22,
      location: "Lac Roumassot - Laruns",
      size: 4,
      position: 1,
    },
    {
      img: img23,
      size: 4,
    },
    {
      img: img24,
      size: 4,
    },
  ],
};
