import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ScrollReveal = ({ children, className, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    // rootMargin: window.innerWidth <= 425 ? "0px" : "-200px", //problem on mobile
    threshold: 0,
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className={className}
      {...props}
      ref={ref}
    >
      {children}
    </motion.div>
  );
};

export default ScrollReveal;
