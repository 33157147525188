import { useEffect, useRef, useState } from "react";
import CanvasScene from "./canvasScene";

import { Canvas } from "@react-three/fiber";
import Sections from "./sections";
import {
  activeCategories,
  comingSoonCategory,
} from "../assets/data/categories";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useHistory } from "react-router-dom";

import VirtualScroll from "virtual-scroll";

// context
import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from "../context/globalContext";
// import MobileSection from "./mobileSection";

const getBackgroundColor = (category) => {
  if (category.comingSoon) {
    return comingSoonCategory.background;
  } else {
    return category.background;
  }
};

const Home = () => {
  const { cursorStyles, cursorType, selectedCategoryIndex, homeLoaded } =
    useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();

  //useRef
  const state = useRef({ speed: 0, position: 0, rounded: 0 });
  const attractMode = useRef(false);
  const attractTo = useRef(0);
  const animatedRef = useRef(null);
  const requestRef = useRef();
  const scroll = useRef();

  let history = useHistory();

  //useState
  const [displayType, setDisplayType] = useState("grid");

  const [animated, setAnimated] = useState(null);

  const [position, setPosition] = useState(0);
  const [selected, setSelected] = useState(0);
  const [display, setDisplay] = useState(true);

  const onResize = () => {
    if (window.innerWidth <= 1024) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: "CURSOR_TYPE", cursorType: cursorType });
  };

  const calc = (e) => {
    //mobile ***

    state.current.speed -= e.deltaY * 0.0003;
  };

  //useEffect

  useEffect(() => {
    scroll.current = new VirtualScroll({
      useTouch: true,
      mouseMultiplier: 0.4,
      useKeyboard: true,
    });

    scroll.current.on(calc);

    onResize();
    window.addEventListener("resize", onResize);
    onCursor();

    raf();

    return () => {
      scroll.current.destroy();
      cancelAnimationFrame(requestRef.current);

      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (selectedCategoryIndex !== null) {
      setAnimated(selectedCategoryIndex);

      if (homeLoaded) {
        state.current.position = selectedCategoryIndex + 0.000001;
        setPosition(state.current.position);
      }
    }
  }, [homeLoaded]);

  useEffect(() => {
    if (displayType === "fullscreen" && selectedCategoryIndex === null) {
      const category = activeCategories[animated];
      if (category) history.push(`/gallery/${category.link}`);
    }
  }, [displayType]);

  const setAnimatedFunc = (id) => {
    if (state.current.rounded === id) {
      setAnimated(id);
    } else {
      animatedRef.current = id;

      setTimeout(() => {
        setAnimated(id);
      }, 500);
    }
  };

  const raf = () => {
    state.current.position += state.current.speed;

    state.current.speed *= 0.8;

    state.current.rounded = Math.round(state.current.position);
    setSelected(state.current.rounded);
    let diff = state.current.rounded - state.current.position;
    if (attractMode.current) {
      state.current.position -=
        (state.current.position - attractTo.current) * 0.03;
    } else if (animatedRef.current !== null) {
      state.current.position -=
        (state.current.position - animatedRef.current) * 0.1;
    } else if (state.current.rounded < 0) {
      state.current.position -= (state.current.position - 1) * 0.03;
    } else if (state.current.rounded > activeCategories.length - 1) {
      state.current.position -= (state.current.position + 1) * 0.003;
    }
    // original else
    else if (displayType !== "fullscreen") {
      state.current.position +=
        Math.sign(diff) * Math.pow(Math.abs(diff), 0.7) * 0.035;
    }
    // to update requestframe
    if (state.current.position === 0) {
      state.current.position = 0.000001;
    }

    setPosition(state.current.position);
    requestRef.current = requestAnimationFrame(raf);
  };

  const mouseEnter = (i) => {
    if (animated === null && !selectedCategoryIndex) {
      attractMode.current = true;
      attractTo.current = i;
      setSelected(i);
    }
  };
  const mouseLeave = () => {
    attractMode.current = false;
  };

  // if (!display) {
  //   return <MobileSection />;
  // }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: homeLoaded ? 1 : 0 }}
      exit={{ opacity: 0, transition: { delay: 0.4 } }}
      id="home"
    >
      <div
        className="about"
        onMouseEnter={() => onCursor("hovered")}
        onMouseLeave={onCursor}
      >
        <Link
          style={{
            color: activeCategories[selected]
              ? activeCategories[selected].secondary
              : "#fff",
          }}
          to="/about"
        >
          ABOUT
        </Link>
      </div>
      <div className="canvas-scene">
        <Canvas
          dpr={window.devicePixelRatio}
          gl={{
            antialias: true,
            pixelRatio: window.devicePixelRatio,
          }}
          camera={{
            fov: 60,
            far: 10,
            position: [0, 0, 2.3],
          }}
        >
          <CanvasScene
            homeLoaded={homeLoaded}
            scrollPos={position}
            attractMode={attractMode.current}
            animated={animated}
            setAnimated={(res) => setAnimatedFunc(res)}
            displayType={displayType}
            setDisplayType={(type) => setDisplayType(type)}
            selectedCategoryIndex={selectedCategoryIndex}
            dispatch={dispatch}
            onCursor={onCursor}
          />
        </Canvas>
      </div>

      <Sections
        setAnimated={(id) => setAnimated(id)}
        animated={animated}
        attractMode={attractMode.current}
        selected={selected}
        onCursor={onCursor}
        cursorType={cursorType}
      />
      {/* Backgrounds */}

      <div
        style={{
          background: activeCategories[selected]
            ? getBackgroundColor(activeCategories[selected])
            : "#fff",
        }}
        className="background"
      ></div>
      {display && (
        <CategorySelector
          animated={animated}
          selected={selected}
          mouseEnter={mouseEnter}
          mouseLeave={mouseLeave}
        />
      )}
    </motion.div>
  );
};

export default Home;

const CategorySelector = ({ animated, selected, mouseEnter, mouseLeave }) => {
  const [display, setDisplay] = useState(true);

  const onResize = () => {
    if (window.innerWidth <= 1366) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: animated === null && display ? 1 : 0 }}
        className={`category-selector`}
      >
        <div>
          {activeCategories.map((elem, i) => {
            const category = elem.comingSoon ? comingSoonCategory : { ...elem };
            return (
              <div
                key={i}
                onMouseEnter={() => mouseEnter(i)}
                onMouseLeave={mouseLeave}
                className="category"
              >
                <motion.span
                  animate={{
                    color: activeCategories[selected]
                      ? activeCategories[selected].secondary
                      : "#000",
                    transition: { delay: 0.6 },
                  }}
                  className="category-name"
                >
                  {category.title}
                </motion.span>
                <motion.div
                  className="selector"
                  animate={
                    selected === i && activeCategories[selected]
                      ? {
                          background: activeCategories[selected].primary,
                          opacity: 1,
                          transition: { delay: 0.6 },
                        }
                      : activeCategories[selected]
                      ? {
                          background: activeCategories[selected].secondary,
                          opacity: 0.3,
                        }
                      : {
                          background: "#000",
                          opacity: 0.3,
                        }
                  }
                ></motion.div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
