// import img01 from "../../images/categories/louvre/louvre_lights_on.jpg";
// import img02 from "../../images/categories/louvre/louvre_lights_on.jpg";
import img01 from "../../images/categories/peru/machu_picchu-landscape.jpg";
import img02 from "../../images/categories/peru/cusco-street_alley.jpg";
import img03 from "../../images/categories/peru/lima-costa_verde_sunset.jpg";
import img04 from "../../images/categories/peru/ollantaytambo-alley.jpg";
import img05 from "../../images/categories/peru/arequipa-mountains_night.jpg";
import img06 from "../../images/categories/peru/chiclayo-street_sunset.jpg";
import img07 from "../../images/categories/peru/arequipa-street_&_mountains.jpg";
import img08 from "../../images/categories/peru/arequipa-street_trafic_2.jpg";
import img09 from "../../images/categories/peru/arequipa-street_trafic.jpg";
import img10 from "../../images/categories/peru/chiclayo-pov.jpg";
import img11 from "../../images/categories/peru/lima-costa_verde_day.jpg";
import img12 from "../../images/categories/peru/ica-dunes_&_sunset.jpg";
import img13 from "../../images/categories/peru/ica-margot_&_dunes.jpg";
import img14 from "../../images/categories/peru/mancora-sunset_pool_view.jpg";
import img15 from "../../images/categories/peru/mancora-sunset_room_view.jpg";
import img16 from "../../images/categories/peru/arequipa-canyon.jpg";
import img17 from "../../images/categories/peru/arequipa-canyon_&_shadows.jpg";
import img18 from "../../images/categories/peru/arequipa-monastery_alley.jpg";
import img19 from "../../images/categories/peru/arequipa-monastery.jpg";
import img20 from "../../images/categories/peru/arequipa-colca_canyon_firecamp.jpg";
import img21 from "../../images/categories/peru/arequipa-colca_canyon_river_sunrise.jpg";
import img22 from "../../images/categories/peru/arequipa-misti_blue_hour.jpg";
import img23 from "../../images/categories/peru/chiclayo-street_yellow_taxi.jpg";
import img24 from "../../images/categories/peru/ollantaytambo-cholitas.jpg";
import img25 from "../../images/categories/peru/machu_picchu-aguas_calientes.jpg";
import img26 from "../../images/categories/peru/machu_picchu-autoportrait.jpg";
import img27 from "../../images/categories/peru/cusco-vinicunca_autoportrait.jpg";

const texts = {
  text_1: {
    fr: ``,
    en: `To be honest, I had some expectation by going to Peru, I don't really know why, but at the same time I wasn't expecting anything about the landscapes, the culture, the food, the people. I just wanted to feel good there. And indeed, first day there, i was feeling good, I was feeling safe. The sunset was stunning, the people were looking nice, and kind. At this moment I though that it will be nice. And I was right ! In the end, I finish by spending more than 3 months there, And I came back with a visa, to live at least for a year.
    
    welcome to Peru !!`,
  },
  text_2: {
    fr: "Mais c'est aussi ce qui fait de ce pays le pays en lui même, ce qui m'a le plus marqué ici c'est ce contraste d'environnement, de culture, de climat, de paysages, et au sein du même pays, a Lima certain quartier sont des plus développés, mais d'autres villes sont plus traditionnelles où le décor et les habitudes sont bien plus chaotique et sans régles qu'ailleurs",
    en: "But this is also what makes this country what it is. What impressed me the most here is the contrast in environment, culture, climate, and landscapes, all within the same country. In Lima, some neighborhoods are very developed, but other cities are more traditional, where the scenery and customs are much more chaotic and rule-free than elsewhere",
  },
  text_3: {
    fr: "D'un coté à un autre, deux ambiance completement differente, et c'est certainement ce qui m'attire le plus ici. Ce qui me fait vibrer, c'est la ville, l'accessibilité. Lima sait me satisfaire. Mais quel bonheur de savoir qu'il n'y a pas que ça dans le pays, que tout est bien plus varié et qu'il y aura toujours à découvrir, à apprendre ...",
    en: "From one side to the other, there are two completely different atmospheres, and that's certainly what attracts me the most here. What excites me is the city and its accessibility. Lima knows how to satisfy me. But what a joy to know that there is more to the country than just that, that everything is much more varied, and that there will always be something to discover and learn ...",
  },
  text_4: {
    fr: "La richesse et variété de tels paysages continuera de me surprendre sans cesse.\n\nEt ça restera un pays dont les coutumes nous dépassent, leur mode de vie n'est pas le notre et pourtant, là bas, ici, je me suis toujours senti accepté, comme un étranger certes (et pas toujours), mais accepté par eux",
    en: "The richness and variety of such landscapes will continue to endlessly surprise me.\n\nAnd it will remain a country with customs that go beyond us; their way of life is not ours, and yet, there, here, I have always felt accepted. As a foreigner, certainly (though not always), but accepted by them.",
  },
  text_5: {
    fr: "Pour être honnête je sais que la décision était la bonne, celle de voyager jusqu'ici, et celle d'y rester.\nJ'en avais des attentes pour ce pays, j'étais un pays effrayé à l'idée de finir par être déçu.\nJ'aurais pu faire de superbe rencontre, apprendre de la vie, une partie qui était aux antipodes de mon mode de vie de l'époque. De ça, j'en aurait tiré des lessons.\nAujourd'hui c'est ici que j'ai décidé de continuer l'aventure de ma vie, de nouveau projets, professionels tout comme personnels.\nCe pays c'est comme le mien, je m'y sens bien, et je l'admire et en parle comme si il était de mon devoir de faire découvrir aux autres mon pays qui au final ne l'est pas, mais est tout comme...",
    en: "To be honest, I know that the decision was the right one—to travel here and to stay. I had high expectations for this country; I was afraid of ending up disappointed. I could have met wonderful people, learned from life, a part of it that was completely different from my way of life at the time. From that, I would have drawn lessons. Today, it is here that I have decided to continue the adventure of my life, with new projects, both professional and personal. This country feels like my own; I feel good here, and I admire and talk about it as if it were my duty to make others discover my country which, in the end, is not mine, but feels like it is...",
  },
  text_6: {
    fr: "Merci pour cette expérience, pour tes paysages, ton accueil, ta nourriture, ton extravagance, merci Perù",
    en: "Thank you for this experience, for your landscapes, your hospitality, your food, your extravagance,\nthank you, Peru.",
  },
};

export const categoryData = {
  src: img01,
  link: "peru",
  title: "Perù",
  description:
    "Peru, a land of ancient ruins, vibrant cultures, and stunning landscapes, invites you to explore its wonders. From the mystical Machu Picchu to the lush Amazon rainforest.",
  background: "#607848",
  primary: "#A5CC09",
  secondary: "#F0F0D8",
  disabled: false,

  categoryImg: { img: img01 },
  themes: {
    yRange: [
      0, 120, 200, 350, 450, 550, 900,
      // cusco flag
      950, 970, 990, 1010, 1030, 1050, 1070,
    ],
    background: [
      "#54B7EE",
      "#FDB698",
      "#000",
      "#000",
      "#D2DCE8",
      "#816156",
      "#06100E",
      // cusco flag
      "#E40304",
      "#FF8C00",
      "#FEED00",
      "#008129",
      "#004DFF",
      "#760689",
      "#ffffff",
    ],
    textColor: [
      "#fff",
      "#fff",
      "#fff",
      "#fff",
      "#604849",
      "#FFD800",
      "#9D92A3",
      // cusco flag
      "#9D92A3",
      "#9D92A3",
      "#9D92A3",
      "#9D92A3",
      "#9D92A3",
      "#9D92A3",
      "#47424A",
    ],
  },
  contents: [
    {
      text: (
        <div className="text description text-align-center">
          <p>{texts.text_1.en}</p>
        </div>
      ),

      size: 12,
    },
    {
      img: img02,
      size: 4,
      location: "cusco",
    },
    {
      img: img03,
      size: 4,
      location: "lima",
    },
    {
      img: img04,
      size: 4,
      location: "ollantaymbo",
    },

    {
      img: img05,
      size: 8,
      // position: 2,
    },
    {
      img: img06,
      size: 4,
    },

    {
      img: img07,
      size: 4,
      location: "arequipa",
    },
    {
      img: img08,
      size: 8,
    },
    {
      img: img09,
      size: 8,
    },
    {
      text: (
        <div className="text description text-align-left">
          <p>{texts.text_2.en}</p>
        </div>
      ),

      size: 4,
    },
    {
      img: img10,
      size: 4,
    },
    {
      text: (
        <div className="text description text-align-right">
          <p>{texts.text_3.en}</p>
        </div>
      ),

      size: 4,
    },
    {
      img: img11,
      size: 4,
    },

    {
      img: img12,
      size: 8,
    },
    {
      img: img13,
      size: 4,
      location: "ica",
    },
    {
      text: (
        <div className="text description text-align-left align-items-start">
          <p>{texts.text_4.en}</p>
        </div>
      ),

      size: 4,
    },
    {
      img: img14,
      size: 4,
    },
    {
      img: img15,
      size: 4,
      location: "mancora",
    },
    {
      img: img16,
      size: 4,
    },
    {
      img: img17,
      size: 8,
    },
    {
      img: img18,
      size: 8,
    },
    {
      img: img19,
      size: 4,
    },
    {
      img: img20,
      size: 4,
    },
    {
      img: img21,
      size: 4,
    },
    {
      img: img22,
      size: 4,
    },

    {
      img: img23,
      size: 4,
    },
    {
      img: img24,
      size: 4,
    },
    {
      img: img25,
      size: 4,
    },

    {
      img: img26,
      size: 5,
      position: 2,
    },
    {
      img: img27,
      size: 5,
      position: 7,
    },

    {
      text: (
        <div className="text description text-align-center">
          <p>{texts.text_5.en}</p>
        </div>
      ),

      size: 10,
      position: 2,
    },
    {
      text: (
        <div className="text description text-align-center">
          <p>{texts.text_6.en}</p>
        </div>
      ),

      size: 10,
      position: 2,
    },
  ],
};
