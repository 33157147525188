import img01 from "../../images/categories/architecture/london_the_city_look_up.jpg";
import img02 from "../../images/categories/architecture/paul_gilbert_novotel.jpg";
import img03 from "../../images/categories/architecture/eva_spiral_stairs.jpg";
import img04 from "../../images/categories/architecture/la_defense_plane.jpg";
import img05 from "../../images/categories/architecture/nora_philharmonie.jpg";
import img06 from "../../images/categories/architecture/adams_bridge.jpg";
import img07 from "../../images/categories/architecture/solenn_tate_modern_museum.jpg";
import img08 from "../../images/categories/architecture/colorful_rainbow_wall.jpg";
import img09 from "../../images/categories/architecture/spiral_stairs_tate_modern.jpg";
import img10 from "../../images/categories/architecture/colorful_corridor.jpg";
import img11 from "../../images/categories/architecture/colorful_windows_building.jpg";

import img12 from "../../images/categories/architecture/tour_kupka.jpg";
import img13 from "../../images/categories/architecture/japan_bridge.jpg";
import img14 from "../../images/categories/architecture/tour_areva.jpg";

import img15 from "../../images/categories/architecture/windows_building.jpg";
import img16 from "../../images/categories/architecture/canary_wharf_station.jpg";
import img17 from "../../images/categories/architecture/sunrise_beaugrenelle.jpg";

import img18 from "../../images/categories/architecture/lombard_street.jpg";
import img19 from "../../images/categories/architecture/dark_look_up.jpg";
import img20 from "../../images/categories/architecture/the_city_from_canary_wharf.jpg";

import img21 from "../../images/categories/architecture/parking_architecture.jpg";
import img22 from "../../images/categories/architecture/pantheon_symetry.jpg";

import img28 from "../../images/categories/architecture/history_museum_london_landscape.jpg";
import img29 from "../../images/categories/architecture/history_museum_london.jpg";

export const categoryData = {
  src: img01,
  link: "architecture",
  title: "Architecture",
  description:
    "probably my favorite type of photo, modern or old architecture, symmetry or asymmetry, everything is good to be taken with a bit of creativity.",
  background: "#64A4D2",
  primary: "#fff",
  secondary: "#000",
  categoryImg: { img: img01, location: "the city - London" },
  themes: {
    yRange: [0, 300, 360, 450],
    background: ["#fff", "#fff", "#FF0038", "#000"],
    textColor: ["#000", "#000", "#fff", "#fff"],
  },
  contents: [
    {
      img: img02,
      location: "paul gilbert x novotel - Paris",
      size: 4,
    },
    {
      img: img03,
      location: "eva - bordeaux",
      size: 4,
    },
    {
      img: img04,
      location: "tours - la Défense Paris",
      size: 4,
    },
    {
      img: img05,
      location: "Nora - Paris",
      size: 4,
    },
    {
      img: img06,
      location: "London",
      size: 8,
    },
    {
      img: img07,
      location: "Solenn - London",
      size: 4,
    },
    {
      img: img08,
      location: "London",
      size: 4,
    },
    {
      img: img09,
      size: 4,
    },
    {
      img: img10,
      location: "London",
      size: 8,
    },
    {
      img: img11,
      size: 4,
    },

    {
      img: img12,
      location: "la Defense",
      size: 4,
    },
    {
      img: img13,
      size: 4,
    },
    {
      img: img14,
      size: 4,
    },

    {
      img: img15,
      size: 4,
    },
    {
      img: img16,
      location: "Canary Wharf",
      size: 4,
    },
    {
      img: img17,
      location: "Paris",
      size: 4,
    },

    {
      img: img18,
      location: "London",
      size: 4,
    },
    {
      img: img19,
      size: 4,
    },
    {
      img: img20,
      location: "Canary Wharf",
      size: 4,
    },

    {
      img: img21,
      location: "Bordeaux",
      size: 8,
    },
    {
      img: img22,
      location: "pantheon - Paris",
      size: 4,
    },

    {
      img: img28,
      location: "Museum - London",
      size: 8,
    },
    {
      img: img29,
      size: 4,
    },
  ],
};
