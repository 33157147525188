import React, { createContext, useReducer, useContext } from "react";

//Define the context
const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

//Reducer

const globalReducer = (state, action) => {
  switch (action.type) {
    case "CURSOR_TYPE": {
      return {
        ...state,
        cursorType: action.cursorType,
      };
    }

    case "SELECTED_CATEGORY_INDEX": {
      return {
        ...state,
        selectedCategoryIndex: action.selectedCategoryIndex,
      };
    }

    case "GALLERY_LOADED": {
      return {
        ...state,
        homeLoaded: action.homeLoaded,
        categoryLoaded: action.categoryLoaded,
      };
    }

    default: {
      throw new Error(`unhandled action type ${action.type}`);
    }
  }
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, {
    cursorType: false,
    cursorStyles: ["hovered", "locked", "back"],
    selectedCategoryIndex: null,
    homeLoaded: false,
    categoryLoaded: false,
  });

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  );
};

//Custom hooks to use dispatch and state
export const useGlobalStateContext = () => useContext(GlobalStateContext);

export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext);
