import questionMark from "../images/question-mark.jpg";
import { categoryData as peru } from "./categories/peru";
import { categoryData as architecture } from "./categories/architecture";
import { categoryData as rooftops } from "./categories/rooftops";
import { categoryData as summerScape } from "./categories/summer";
import { categoryData as portrait } from "./categories/portrait";
import { categoryData as marmandeChurch } from "./categories/marmandeChurch";
import { categoryData as louvre } from "./categories/louvre";
import { categoryData as operaGarnier } from "./categories/operaGarnier";
import { categoryData as grandPalais } from "./categories/grandPalais";
import { categoryData as mountain } from "./categories/mountain";
import { categoryData as blackForest } from "./categories/blackForest";
import { categoryData as moments } from "./categories/moments";
import { categoryData as moon } from "./categories/moon";
import { categoryData as montreal } from "./categories/montreal";
import { categoryData as newYork } from "./categories/newYork";

export const comingSoonCategory = {
  src: questionMark,
  link: "",
  title: "Coming Soon",
  description: "stay tuned",
  background: "#415127",
  primary: "#fff",
  secondary: "#fff",
};

export const categories = [
  peru,
  newYork,
  montreal,
  moon,
  operaGarnier,
  rooftops,
  architecture,
  summerScape,
  portrait,
  marmandeChurch,
  louvre,
  grandPalais,
  mountain,
  blackForest,
  moments,
];

export const activeCategories = categories;

// ***** EASTER EGG ***** //

// export const activeCategories =
//   localStorage.getItem("unlock-disabled-categories") === "true"
//     ? categories
//     : categories.filter((category) => !category.disabled);
