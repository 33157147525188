import { AnimatePresence, motion } from "framer-motion";
import {
  activeCategories,
  comingSoonCategory,
} from "../assets/data/categories";

const Sections = ({
  selected,
  attractMode,
  setAnimated,
  animated,
  cursorType,
  onCursor,
}) => {
  return (
    <div id="sections">
      <AnimatePresence exitBeforeEnter>
        {activeCategories.map((elem, i) => {
          const category = elem.comingSoon ? comingSoonCategory : { ...elem };

          if (selected === i && !attractMode && animated === null)
            return (
              <motion.div
                key={i}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3 }}
                className="section"
              >
                <h1 className="title" style={{ color: category.primary }}>
                  {category.title}
                </h1>
                <p
                  style={{ color: category.secondary }}
                  className="description"
                >
                  {category.description}
                </p>

                {category.link && (
                  <div
                    onClick={() => setAnimated(i)}
                    onMouseEnter={() => onCursor("hovered")}
                    onMouseLeave={onCursor}
                    style={{ color: category.secondary }}
                    className="button"
                  >
                    Access to galery
                    <motion.span
                      animate={{ x: cursorType === "hovered" ? 10 : 0 }}
                      transition={{ duration: 0.3 }}
                      className="arrow"
                      style={{ background: category.primary }}
                    >
                      <div
                        className="top"
                        style={{ background: category.primary }}
                      ></div>
                      <div
                        className="bottom"
                        style={{ background: category.primary }}
                      ></div>
                    </motion.span>
                  </div>
                )}
              </motion.div>
            );
        })}
      </AnimatePresence>
    </div>
  );
};

export default Sections;
