import { BrowserRouter, Redirect, Route } from "react-router-dom";

import CustomCursor from "./components/customCursor";

import { useState } from "react";
import { isMobile } from "react-device-detect";

import SplashScreen from "./components/SplashScreen";
import About from "./components/About";
import Gallery from "./components/Gallery";
// import MobileSection from "./components/mobileSection";
// import WIP from "./components/WIP";

function App() {
  const [hidden, setHidden] = useState(false);

  return (
    <>
      {!isMobile && <CustomCursor />}

      <BrowserRouter>
        {!hidden && (
          <SplashScreen setHidden={() => setHidden(true)} key="splascreen" />
        )}
        <Router />
      </BrowserRouter>
    </>
  );
}

export default App;

const Router = () => {
  return (
    <>
      {/* WIP structure */}
      {/* <Route key="/" exact path="/" render={() => <Redirect to="/wip" />} /> */}
      {/* <WIP /> */}
      {/* <Route key="*" exact path="*" render={() => <Redirect to="/wip" />} /> */}

      {/* Basic structure */}

      <Route key="gallery" path="/gallery/:category?">
        <Gallery />
      </Route>
      <Route key="about" path="/about">
        <About />
      </Route>
      <Route key="/" path="/" exact render={() => <Redirect to="/gallery" />} />
    </>
  );
};
