import gsap from "gsap";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useGlobalStateContext } from "../context/globalContext";

const SplashScreen = ({ setHidden }) => {
  const history = useHistory();
  const { homeLoaded, categoryLoaded } = useGlobalStateContext();
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    const tl = gsap.timeline();

    const child1 = document.querySelector(`.backgrounds div:nth-child(1)`);

    tl.to(child1, {
      transform: "scale(1) rotate(0deg) translate(-50%, 0%)",
      duration: 1,
      ease: "power2.inOut",
      delay: 0,
    }).to(child1, {
      transform: "scale(1) translate(-50%, 0%) rotate(90deg)",
      duration: 0.75,
      delay: 0,
      ease: "power2.inOut",
    });
    tl.eventCallback("onComplete", () => setComplete(true));
  }, []);

  useEffect(() => {
    const tl = gsap.timeline();
    const child1 = document.querySelector(`.backgrounds div:nth-child(1)`);

    const child2 = document.querySelector(`.backgrounds div:nth-child(2)`);
    const child3 = document.querySelector(`.backgrounds div:nth-child(3)`);
    const child4 = document.querySelector(`.backgrounds div:nth-child(4)`);
    if (
      complete &&
      (homeLoaded ||
        categoryLoaded ||
        !history.location.pathname.includes("/gallery"))
    ) {
      tl.to(child1, {
        transform: "scale(1) translate(-45%, 0%) rotate(90deg)",
        duration: 1,
        ease: "power4.inOut",
      });

      tl.to(child1, {
        transform: "scale(1) translate(-100%, 0%) rotate(90deg)",
        duration: 1,
        ease: "power2.inOut",
      });

      //**  closing panels **//
      gsap.to(child2, {
        width: 0,
        duration: 2,
        ease: "power4.inOut",
        delay: 0.4,
      });

      gsap.to(child3, {
        width: 0,
        duration: 2,
        ease: "power4.inOut",
        delay: 0.5,
      });

      gsap.to(child4, {
        width: 0,
        duration: 2,
        ease: "power4.inOut",
        delay: 0.6,
      });

      gsap
        .to("#splashscreen", {
          width: 0,
          duration: 2,
          ease: "power4.inOut",
          delay: 0.6,
        })
        .eventCallback("onComplete", setHidden);
    }
  }, [complete, homeLoaded, categoryLoaded]);

  return (
    <div id="splashscreen">
      <div className="container">
        <div className="backgrounds">
          <div className="black-bg"></div>
          <div className="white-bg"></div>
          <div className="black-bg"></div>
          <div className="white-bg"></div>
        </div>

        <div className="dash"></div>
        <div className="name">
          <p>COLIN SCHMITT</p>
        </div>
      </div>
      {isMobile && (
        <span className="mobile-prevention">100x better on desktop</span>
      )}
    </div>
  );
};

export default SplashScreen;
